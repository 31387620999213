<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent>
      <v-text-field class="col"
                    label="使用者名稱"
                    v-model="username"
                    :rules="usernameRules"
      />
      <v-text-field class="col"
                    label="密碼"
                    v-model="password"
                    :append-outer-icon="'vpn_key'"
                    :rules="passwordRules"
                    @click:append-outer="randomPassword"
      />
    </v-form>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary"
             @click="send">
        儲存
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {mapActions} from "pinia";
import {toast, useLoadingStore} from "@/Store";
import {createUser} from "@/Api";

export default {
  name: "CreateUserFrom",
  data() {
    return {
      username: '',
      usernameRules: [
        v => !!v || '必填'
      ],
      password: '',
      passwordRules: [
        v => !!v || '必填'
      ],
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    randomPassword() {
      this.password = `${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}`
    },
    async send() {
      if (!this.$refs.form.validate()) return
      this.showLoading()
      const response = await createUser(this.username, this.password)
      this.$refs.form.reset()
      if (response) {
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>