<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" class="text-end">設備ID</v-col>
      <v-col cols="10" class="text-start" v-if="title.length < 20"> {{ title }}</v-col>
      <v-col cols="10" class="text-start" v-else>
        <v-menu content-class="max-h-50" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ title }}...</span>
          </template>
          <v-card>
            <v-card-text class="overflow-scroll">
              <span>{{ deviceId.join(',') }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="2" class="text-end">新增標籤</v-col>
      <v-col cols="10">
        <v-chip class="m-1" text-color="white"
                v-for="tag in deviceTags.filter(item=>{
                    if (item.includes('C:')) return false
                    if (item.includes('F:')) return false
                    if (item.includes('.hide')) return false
                    return true
                  })"
                :key="tag"
                :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'"
                @click="remove(tag)">
          {{ tag.split('.')[0] }}
        </v-chip>
      </v-col>
    </v-row>
    <v-divider/>
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="p-0" expand-icon="arrow_drop_down">
          <v-form ref="form" @submit.prevent>
            <v-text-field class="me-3"
                          label="新標籤"
                          v-model="word"
                          append-outer-icon="ios_share"
                          :rules="wordRules"
                          @click:append-outer="addWord"
            />
          </v-form>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip class="m-1" text-color="white"
                  v-for="tag in tags.filter(tag=>!deviceTags.includes(tag))"
                  :key="tag"
                  :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'"
                  @click="add(tag)">
            {{ tag.split('.')[0] }}
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save">儲存</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {mainStore, useLoadingStore, useTagStore} from "@/Store";
import {updateTags} from "@/Api";

export default {
  name: "DeviceTagArrayAddTagForm",
  props: {
    deviceId: Array,
  },
  computed: {
    ...mapState(useTagStore, ['tags']),
  },
  data() {
    return {
      title: this.deviceId.join(',').substring(0, 20),
      deviceTags: [],
      word: '',
      wordRules: [
        v => !!v || '必填',
        v => !this.deviceTags.includes(v) || '重複',
      ],
    }
  },
  methods: {
    ...mapActions(useTagStore, {'init': 'init', 'addTag': 'add'}),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['reloadDevices']),
    addWord() {
      if (!this.$refs.form.validate()) return
      this.add(this.word)
      this.$refs.form.reset()
    },
    add(tag) {
      if (!tag) return
      this.deviceTags.push(tag)
      this.addTag(tag)
    },
    remove(tag) {
      this.deviceTags = this.deviceTags.filter(t => t !== tag)
    },
    async save() {
      this.showLoading()
      await updateTags(this.deviceId, this.deviceTags, [])
      await this.reloadDevices()
      this.dismissLoading()
      this.$emit('close')
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
</style>