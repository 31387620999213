<template>
  <v-container fluid>
    <v-row>
      <v-col cols="1">
        <v-btn icon color="primary" @click="$router.back()">
          <v-icon>arrow_back_ios_new</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-end">
        <v-btn color="primary" @click="save">儲存</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-card>
      <v-card-title>{{ username }}</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="text-end">角色</v-col>
          <v-col>
            <v-menu right offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="w-100" color="primary" v-bind="attrs" v-on="on">
                  <div v-show="role==='ROOT'">管理員</div>
                  <div v-show="role==='MANAGER'">經理</div>
                  <div v-show="role==='STAFF'">員工</div>
                  <div v-show="role==='LEADER'">組長</div>
                  <div v-show="role==='MEMBER'">合作夥伴</div>
                  <div v-show="role==='USER'">使用者</div>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="role='ROOT'">
                  <v-list-item-content>
                    <v-list-item-title class="red--text">管理員</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="role='MANAGER'">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">經理</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="role='STAFF'">
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">員工</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="role='LEADER'" hidden>
                  <v-list-item-content>
                    <v-list-item-title>組長</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="role='MEMBER'">
                  <v-list-item-content>
                    <v-list-item-title class="amber--text">合作夥伴</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="role='USER'">
                  <v-list-item-content>
                    <v-list-item-title>使用者</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>資訊平台權限</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-item-group>
          <v-btn v-for="item in firstPermissionsItems"
                 :key="item.value"
                 :color="(firstPermissions & item.value) === item.value?'primary':''"
                 @click="(firstPermissions & item.value) === item.value?firstPermissions-=item.value:firstPermissions+=item.value"
                 class="m-1"
                 ripple>
            <v-icon left v-show="(firstPermissions & item.value) === item.value">check_box</v-icon>
            <v-icon left v-show="(firstPermissions & item.value) !== item.value">check_box_outline_blank</v-icon>
            {{ item.name }}
          </v-btn>
        </v-item-group>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title>車檢器設定權限</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-item-group>
          <v-btn v-for="item in vehicleDetectorSettingItems"
                 :key="item.value"
                 :color="(vehicleDetectorSetting & item.value) === item.value?'primary':''"
                 @click="(vehicleDetectorSetting & item.value) === item.value?vehicleDetectorSetting-=item.value:vehicleDetectorSetting+=item.value"
                 class="m-1"
                 ripple>
            <v-icon left v-show="(vehicleDetectorSetting & item.value) === item.value">check_box</v-icon>
            <v-icon left v-show="(vehicleDetectorSetting & item.value) !== item.value">check_box_outline_blank</v-icon>
            {{ item.name }}
          </v-btn>
        </v-item-group>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {getUser, adminPatchUser} from "@/Api";
import {mapActions} from "pinia";
import {toast, useLoadingStore} from "@/Store";

export default {
  name: "UserEditFrom",
  props: {
    username: String,
  },
  data() {
    return {
      userId: undefined,
      role: undefined,
      firstPermissions: 0,
      vehicleDetectorSetting: 0,
      firstPermissionsItems: [
        {value: 0b000000000000000000000000000001, name: '使用者讀取'},
        {value: 0b000000000000000000000000000010, name: '使用者寫入'},
        {value: 0b000000000000000000000000000100, name: '車檢器車位變化紀錄讀取'},
        {value: 0b000000000000000000000000001000, name: '車檢器封包紀錄讀取'},
        {value: 0b000000000000000000000000010000, name: '車檢器異常紀錄讀取'},
        {value: 0b000000000000000000000000100000, name: '車檢器原始封包紀錄讀取'},
        {value: 0b000000000000000000000001000000, name: '車檢器網路數據讀取'},
        {value: 0b000000000000000000000010000000, name: '車檢器設定讀寫'},
        {value: 0b000000000000000000000100000000, name: '車檢器韌體與升級讀寫'},
        {value: 0b000000000000000000001000000000, name: '車檢器標籤讀寫'},
        {value: 0b000000000000000000010000000000, name: '組織與場域讀取'},
        {value: 0b000000000000000000100000000000, name: '組織與場域寫入'},
        {value: 0b000000000000000001000000000000, name: '停車格功能讀取'},
        {value: 0b000000000000000010000000000000, name: '停車格功能寫路'},
        {value: 0b000000000000000100000000000000, name: '停車格統計讀取'},
        {value: 0b000000000000001000000000000000, name: '操作紀錄讀取'}
      ],
      vehicleDetectorSettingItems: [
        {value: 0b0000000000000000000000000000001, name: '主要ip位置'},
        {value: 0b0000000000000000000000000000010, name: '副ip位置'},
        {value: 0b0000000000000000000000000000100, name: '通訊埠'},
        {value: 0b0000000000000000000000000001000, name: '初始X值'},
        {value: 0b0000000000000000000000000010000, name: '初始Y值'},
        {value: 0b0000000000000000000000000100000, name: '初始Z值'},
        {value: 0b0000000000000000000000100000000, name: '地磁變化閥值'},
        {value: 0b0000000000000000000001000000000, name: '紅外變化閥值'},
        {value: 0b0000000000000000000010000000000, name: '四維向量變化閥值'},
        {value: 0b0000000000000000000100000000000, name: '心跳包間隔'},
        {value: 0b0000000000000000001000000000000, name: '地磁觸發閥值'},
        {value: 0b0000000000000000010000000000000, name: '地磁穩定閥值'},
        {value: 0b0000000000000000100000000000000, name: '地磁穩定時間'},
        {value: 0b0000000000000001000000000000000, name: '恢復初始化'},
        {value: 0b0000000000000010000000000000000, name: 'T3412'},
        {value: 0b0000000000000100000000000000000, name: 'T3324'},
        {value: 0b0000000000001000000000000000000, name: 'IP3心跳包間隔'},
        {value: 0b0000000000010000000000000000000, name: 'IMEI與多IP項目'},
        {value: 0b0000000000100000000000000000000, name: '設備啟用'}

      ]
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(toast, ['success', 'error']),
    async loadUser() {
      let user = await getUser(this.username)
      this.userId = user.id
      this.role = user.role
      this.firstPermissions = user.firstPermissions
      this.vehicleDetectorSetting = user.vehicleDetectorSetting
    },
    async save() {
      let user = {
        userId: this.userId,
        role: this.role,
        firstPermissions: this.firstPermissions,
        vehicleDetectorSetting: this.vehicleDetectorSetting
      }
      this.showLoading()
      const response = await adminPatchUser(user)
      if (response) {
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
    }
  },
  mounted() {
    this.loadUser().then()
  }
}
</script>

<style scoped>

</style>