<template>
  <v-container fluid>
    <v-form ref="form">
      <v-row>
        <v-col cols="3" class="text-end text-lg-h6">韌體名稱</v-col>
        <v-text-field class="col"
                      v-model="name"
                      :rules="nameRules"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="3" class="text-end text-lg-h6">韌體版本</v-col>
        <v-text-field class="col"
                      v-model="version"
                      :rules="versionRules"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="3" class="text-end text-lg-h6">適用批號</v-col>
        <v-text-field class="col"
                      type="number"
                      v-model="bid"
                      :rules="bidRules"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="3" class="text-end text-lg-h6">韌體檔案</v-col>
        <v-file-input class="col p-0"
                      v-model="file"
                      :rules="fileRules"
                      accept=".bin"
                      truncate-length="100"
                      prepend-icon="attach_file"
                      clear-icon="cancel"
                      clearable
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="3" class="text-end text-lg-h6">韌體說明</v-col>
        <v-textarea class="col"
                    v-model="description"
                    :rules="descriptionRules"
                    outlined/>
      </v-row>
    </v-form>
    <v-divider/>
    <v-card-actions class="p-0 m-0">
      <v-spacer/>
      <v-btn color="primary" @click="upload">上傳</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {uploadFirmware} from "@/Api";
import {mapActions} from "pinia";
import {useLoadingStore} from "@/Store";

export default {
  name: "UploadFirmwareFrom",
  data() {
    return {
      name: '',
      nameRules: [
        v => !!v || '缺少韌體名稱',
        v => (v && v.length <= 191) || '韌體名稱過長',
      ],
      bid: '',
      bidRules: [
        v => !!v || '缺少適用批號',
        v => (v && parseInt(v)) || '適用批號為數字',
      ],
      version: '',
      versionRules: [
        v => !!v || '缺少韌體版本',
        v => (v && v.length <= 191) || '韌體版本過長',
      ],
      file: undefined,
      fileRules: [
        v => !!v || '缺少韌體檔案',
      ],
      description: '',
      descriptionRules: [
        v => !!v || '缺少韌體說明',
        v => (v && v.length <= 191) || '說明內文過長',
      ],
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async upload() {
      if (!this.$refs.form.validate()) return
      this.showLoading()
      let firmware = await uploadFirmware(this.name, this.bid, this.version, this.description, this.file)
      this.$refs.form.reset()
      this.dismissLoading()
      this.$emit('close', firmware)
    }
  }
}
</script>

<style scoped>

</style>