<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn icon color="primary" @click="$router.back()">
          <v-icon>arrow_back_ios_new</v-icon>
        </v-btn>
      </v-col>
      <v-col col="12" sm="2">
        <v-btn color="primary" @click="showCreateFieldModal = true" right>新增</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="devices" :options="options">
      <template slot="action" slot-scope="{row}">
        <v-btn-toggle>
          <v-btn color="error" small outlined @click="selectField=row;showDeleteFieldModal=true">刪除</v-btn>
        </v-btn-toggle>
      </template>
    </v-client-table>
    <v-dialog scrollable
              v-model="showCreateFieldModal"
              @click:outside="showCreateFieldModal = false">
      <v-card>
        <v-card-title>
          <label>新增場域</label>
          <v-spacer/>
          <v-btn icon @click="showCreateFieldModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field label="名稱" v-model="newName" :rules="nameRules" :counter="255"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 right
                 @click="sendCreate">儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable
              v-model="showDeleteFieldModal"
              @click:outside="showDeleteFieldModal=false">
      <v-card>
        <v-card-title>
          <label>刪除場域</label>
          <v-spacer/>
          <v-btn icon @click="showDeleteFieldModal=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center fs-4">
          確認刪除 {{ selectField?.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 right
                 @click="sendDelete">確定
          </v-btn>
          <v-btn color="error"
                 right
                 @click="showDeleteFieldModal=false">取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {createField, deleteField, getAllField} from "@/Api";
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";

/**
 * 場域編輯頁面
 * */
export default {
  name: "FieldFrom",
  props: {
    organizationAppId: String,
  },
  computed: {
    ...mapState(mainStore, ['selectOrganizationAppId']),
  },
  data() {
    return {
      columns: ['name', 'action'],
      devices: [],
      options: {
        headings: {
          name: '名稱',
          action: '操作'
        },
        orderBy: {ascending: true, column: 'name'},
        sortable: ['name'],
        filterable: false,
      },
      showCreateFieldModal: false,
      showDeleteFieldModal: false,
      selectField: undefined,
      newName: '',
      nameRules: [
        v => !!v || '缺少名稱',
        v => (v && v.length <= 255) || '名稱過長',
      ],
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['reloadOrganizations']),
    async loadFields() {
      this.showLoading()
      this.devices = await getAllField(this.organizationAppId)
      this.dismissLoading()
    },
    async sendCreate() {
      if (!this.$refs.form.validate()) return
      this.showLoading()
      const success = await createField({organizationAppId: this.organizationAppId, name: this.newName})
      if (success) {
        this.success('新增成功')
      } else {
        this.error('新增失敗')
      }
      this.$refs.form.reset()
      this.showCreateFieldModal = false
      await this.loadFields()
      await this.reloadOrganizations()
      this.dismissLoading()
    },
    async sendDelete() {
      this.showLoading()
      const id = this.selectField?.id
      if (id) {
        const success = await deleteField(id)
        if (success) {
          this.success('刪除成功')
        } else {
          this.error('刪除失敗')
        }
      }
      this.selectField = undefined
      this.showDeleteFieldModal = false
      await this.loadFields()
      await this.reloadOrganizations()
      this.dismissLoading()
    }
  },
  mounted() {
    this.loadFields().then()
  }
}
</script>

<style scoped>

</style>