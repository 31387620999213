<script>
import EmblaCarousel from 'embla-carousel'
import {mapActions} from "pinia";
import {useLoadingStore} from "@/Store";
import {getParkingPoleUploadImageLocalUrl} from "@/Api-parking-pole";

export default {
  name: "FileViewer",
  props: {
    fileId: String,
  },
  data() {
    return {
      emblaApi: null,
      fileList: [],
    }
  },
  computed: {
    colors() {
      return Array.from({ length: 20 }, (_, i) => {
        const hue = (i * 137.508) % 360;
        return `hsl(${hue}, 80%, 80%)`;
      });
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    scrollPrev() {
      this.emblaApi?.scrollPrev()
    },
    scrollNext() {
      this.emblaApi?.scrollNext()
    },
    async loadImage() {
      this.fileList.push({
        type: 'image',
        url: await getParkingPoleUploadImageLocalUrl(this.fileId)
      })
    }
  },
  async mounted() {
    const emblaRef = this.$refs.emblaRef
    const options = { skipSnaps: true }
    this.emblaApi = EmblaCarousel(emblaRef, options)

    this.showLoading()
    if (this.fileId) {
      await this.loadImage()
    }
    this.dismissLoading()
  },
}
</script>

<template>
  <v-card height="80%">
    <v-card-title>
      <span>查看檔案</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div>
            <div ref="emblaRef" class="embla">
              <div class="embla__container">
                <div class="embla__slide" v-for="(file, i) in fileList" :key="i" :style="{ backgroundColor: colors[i] }">
                  <!-- file name -->
                  <div>
                    <span>{{ fileId }}</span>
                  </div>
                  <v-img :src="file.url" :key="file.index"/>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="2">
            <v-btn @click="scrollPrev" fab small><v-icon>fa-arrow-left</v-icon></v-btn>
        </v-col>
        <v-col cols="2">
            <v-btn @click="scrollNext" fab small><v-icon>fa-arrow-right</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  margin-left: 10px;
  margin-right: 10px;
}
</style>