<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import {mapActions} from "pinia";
import {userLocation} from "@/Store";

export default {
  name: 'App',
  methods: {
    ...mapActions(userLocation, {
      locationInit: 'init'
    }),
  },
  mounted() {
    this.locationInit()
  }
}
</script>

<style>
</style>
