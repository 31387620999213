export function filterDeviceWithKeywordAndExcludeKeyword(device, keyword, excludeKeyword, userRole) {
    if (`${keyword}${excludeKeyword}`.length === 0) {
        return true
    }
    const string = userRole === 'USER' ? `${device.deviceId},${device.address},${device.name}` : `${device.deviceId},${device.address},${device.name},${(device.deviceTags ?? []).join(',')
        .replaceAll('E:DISCONNECTED', '斷線異常')
        .replaceAll('E:LOW_POWER', '低電量異常')
        .replaceAll('E:MAGNETIC', '磁場異常')
        .replaceAll('E:INFRARED', '紅外異常')
        .replaceAll('E:BOR', '復位異常')
        .replaceAll('E:COVER', '遮蔽異常')
        .replaceAll('E:INIT', '初始化異常')
        .replaceAll('E:RSRP', '訊號異常')
        .replaceAll('E:SINR', '信噪比異常')
        .replaceAll('E:SIM', 'SIM卡異常')
        .replaceAll('E:LONG_PARKING', '疑似長時間停車')
        .replaceAll('E:NETWORK', '網路異常')}`
    let excludeKeywords = `${excludeKeyword}`.split(",")
    for (let i in excludeKeywords) {
        if (excludeKeywords[i].length === 0) continue
        if (string.includes(excludeKeywords[i])) return false
    }
    let keywords = keyword.split(",")
    for (let i in keywords) {
        if (keywords[i].length === 0) continue
        let ks = keywords[i].split("+").map(k => k.trim())
        let result = true
        for (let j in ks) {
            result = result && string.includes(ks[j])
        }
        if (result) return true
    }
    return keyword.length === 0
}

export function rad(value) {
    return value * Math.PI / 180.0
}

export function distance(lat1, lng1, lat2, lng2) {
    let havLat = Math.sin(rad(lat1 - lat2) / 2)
    let havLon = Math.sin(rad(lng1 - lng2) / 2)
    let a = havLat * havLat + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * havLon * havLon;
    return 2 * 6378.137 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 1000;
}

import userGreyMark from "@/assets/parking-mark-grey.svg";
import greyMark from "@/assets/map-mark-grey.svg";
import userGreenPurpleMark from "@/assets/parking-mark-green-purple.svg";
import userPinkPurpleMark from "@/assets/parking-mark-pink-purple.svg";
import userRedPurpleMark from "@/assets/parking-mark-red-purple.svg";
import userGreenMark from "@/assets/parking-mark-green.svg";
import userPinkMark from "@/assets/parking-mark-pink.svg";
import userRedMark from "@/assets/parking-mark-red.svg";
import greenPurpleMark from "@/assets/map-mark-green-purple.svg";
import pinkPurpleMark from "@/assets/map-mark-pink-purple.svg";
import redPurpleMark from "@/assets/map-mark-red-purple.svg";
import greenMark from "@/assets/map-mark-green.svg";
import pinkMark from "@/assets/map-mark-pink.svg";
import redMark from "@/assets/map-mark-red.svg";
export function getIcon(device, userRole) {
    let isUser = userRole === 'USER'
    if (!device.lastStatus) return isUser ? userGreyMark : greyMark
    let tag = (device.deviceTags ?? []).join(',')
    let haveError = tag.includes('E:DISCONNECTED')
        || tag.includes('E:LOW_POWER')
        || tag.includes('E:MAGNETIC')
        || tag.includes('E:INFRARED')
        || tag.includes('E:BOR')
        || tag.includes('E:COVER')
    if (isUser) {
        if (haveError) {
            if (device.lastStatus.cp < 50) {
                return userGreenPurpleMark
            } else if (device.lastStatus.cp < 80) {
                return userPinkPurpleMark
            } else {
                return userRedPurpleMark
            }
        } else {
            if (device.lastStatus.cp < 50) {
                return userGreenMark
            } else if (device.lastStatus.cp < 80) {
                return userPinkMark
            } else {
                return userRedMark
            }
        }
    } else {
        if (haveError) {
            if (device.lastStatus.cp < 50) {
                return greenPurpleMark
            } else if (device.lastStatus.cp < 80) {
                return pinkPurpleMark
            } else {
                return redPurpleMark
            }
        } else {
            if (device.lastStatus.cp < 50) {
                return greenMark
            } else if (device.lastStatus.cp < 80) {
                return pinkMark
            } else {
                return redMark
            }
        }
    }
}

/**
 * @returns {Promise<boolean>}
 */
export function mockApiDone(isSuccess) {
    if (isSuccess) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true)
            }, 1000)
        })
    } else {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(false)
            }, 1000)
        })
    }
}