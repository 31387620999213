<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent>
      <v-row v-show="this.parkingPosition.deviceId">
        <v-col cols="6" class="text-end text-lg-h5">目前綁定設備</v-col>
        <v-col cols="6" class="text-start text-lg-h5">{{ parkingPosition.deviceId }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="text-end text-lg-h5">綁定設備ID</v-col>
        <v-autocomplete class="col-6"
                        outlined
                        v-model="newDeviceId"
                        item-text="deviceId"
                        item-value="deviceId"
                        :rules="deviceIdRules"
                        :items="this.devices.filter(device => device.deviceId)"
        />
      </v-row>
    </v-form>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="send">送出</v-btn>
      <v-btn color="error" @click="unbind" v-show="this.parkingPosition.deviceId">解除綁定</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";
import {patchPosition, unbindPosition} from "@/Api";

export default {
  name: "BingDeviceFrom",
  props: {
    parkingPosition: Object,
  },
  computed: {
    ...mapState(mainStore, ['devices']),
  },
  data() {
    return {
      newDeviceId: '',
      deviceIdRules: [
        v => !!v || '必填',
      ],
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['updateDeviceView']),
    async send() {
      if (!this.$refs.form.validate()) return
      this.showLoading()
      let response = await patchPosition(this.parkingPosition.id, {deviceId: this.newDeviceId})
      if (response) {
        await this.updateDeviceView(this.parkingPosition, response)
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
      this.$emit('close');
    },
    async unbind() {
      this.showLoading()
      let response = await unbindPosition(this.parkingPosition.id)
      if (response) {
        await this.updateDeviceView(this.parkingPosition, response)
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>