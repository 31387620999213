<template>
  <v-container fluid>
    <v-form ref="form">
      <v-row>
        <v-col cols="4" class="text-end text-lg-h5">名稱</v-col>
        <v-text-field class="col"
                      v-model="name"
                      :rules="nameRules"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="4" class="text-end text-lg-h5">車格號碼</v-col>
        <v-text-field class="col"
                      v-model="nameCode"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="4" class="text-end text-lg-h5">路段</v-col>
        <v-text-field class="col"
                      v-model="address"
                      :rules="addressRules"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="4" class="text-end text-lg-h5">路段代碼</v-col>
        <v-text-field class="col"
                      v-model="addressCode"
                      outlined/>
      </v-row>
      <v-row>
        <v-col cols="4" class="text-end text-lg-h5">是否為高靈敏度車格</v-col>
        <v-col>
          <v-btn text @click="isHighSensitivity=false">
            <v-icon color="primary" v-if="!isHighSensitivity">radio_button_checked</v-icon>
            <v-icon v-else>radio_button_unchecked</v-icon>
            <span class="text-lg-h5">否</span>
          </v-btn>
          <v-btn text @click="isHighSensitivity=true">
            <v-icon color="primary" v-if="isHighSensitivity">radio_button_checked</v-icon>
            <v-icon v-else>radio_button_unchecked</v-icon>
            <span class="text-lg-h5">是</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="parkingPosition.id">
        <v-col cols="4" class="text-end red--text text-lg-h5">刪除</v-col>
        <v-col>
          <v-btn text @click="isDelete=false">
            <v-icon color="primary" v-if="!isDelete">radio_button_checked</v-icon>
            <v-icon v-else>radio_button_unchecked</v-icon>
            <span class="text-lg-h5">否</span>
          </v-btn>
          <v-btn text @click="isDelete=true">
            <v-icon color="primary" v-if="isDelete">radio_button_checked</v-icon>
            <v-icon v-else>radio_button_unchecked</v-icon>
            <span class="text-lg-h5">是</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="m-0">
        <GmapMap style="width: 100%;height: 200px"
                 ref="map"
                 :center="{lat:parseFloat(lat??userLat),lng:parseFloat(lng??userLng)}"
                 :zoom="16"
                 :position="google"
                 :options="{
                   zoomControl: false,
                   mapTypeControl: false,
                   scaleControl: false,
                   streetViewControl: false,
                   rotateControl: false,
                   fullscreenControl: false,
                   disableDefaultUI: false
                 }"
                 @click="onMapClicked($event.latLng)">
          <GmapMarker
              :position="{lat:parseFloat(lat??userLat),lng:parseFloat(lng??userLng)}"
              draggable
              @dragend="onMove($event.latLng)"
          />
        </GmapMap>
      </v-row>
      <v-row>
        <v-text-field class="col-5"
                      type="number"
                      prefix="經度"
                      v-model="lng"/>
        <v-text-field class="col-5"
                      type="number"
                      prefix="緯度"
                      v-model="lat"/>
      </v-row>
    </v-form>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="send">儲存</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {gmapApi} from "vue2-google-maps";
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore, userLocation} from "@/Store";
import {createOrUpdatePosition} from "@/Api";

export default {
  name: "PositionCreateOrUpdateFrom",
  props: {
    parkingPosition: Object,
  },
  computed: {
    google: gmapApi,
    ...mapState(userLocation, {userLat: 'lat', userLng: 'lng'}),
  },
  data() {
    return {
      name: this.parkingPosition.name,
      nameCode: this.parkingPosition.nameCode,
      address: this.parkingPosition.address,
      addressCode: this.parkingPosition.addressCode,
      lat: this.parkingPosition?.lat ?? this.userLat,
      lng: this.parkingPosition?.lng ?? this.userLng,
      isDelete: this.parkingPosition.isDelete,
      isHighSensitivity: this.parkingPosition.isHighSensitivity,
      deviceId: this.parkingPosition.deviceId,
      organizationAppId: this.parkingPosition.organizationAppId,
      districtAppId: this.parkingPosition.districtAppId,
      nameRules: [
        v => !!v || '缺少名稱',
      ],
      addressRules: [
        v => !!v || '缺少路段',
      ]
    }
  },
  mounted() {
    this.lat = this.parkingPosition?.lat ?? this.userLat
    this.lng = this.parkingPosition?.lng ?? this.userLng
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['updateDeviceView']),
    async send() {
      if (!this.$refs.form.validate()) return
      let params = {
        id: this.parkingPosition.id,
        name: this.name,
        nameCode: this.nameCode,
        address: this.address,
        addressCode: this.addressCode,
        lat: this.lat ?? this.userLat,
        lng: this.lng ?? this.userLng,
        isDelete: this.parkingPosition.id ? this.isDelete : false,
        isHighSensitivity: this.parkingPosition.id ? this.isHighSensitivity : false,
        deviceId: this.deviceId,
        organizationAppId: this.organizationAppId,
        districtAppId: this.districtAppId
      }
      this.showLoading()
      let response = await createOrUpdatePosition(params)
      if (response.ok) {
        this.success(this.parkingPosition.id ? '更新成功' : '新增成功')
        await this.updateDeviceView(this.parkingPosition, response.data)
      } else {
        this.error(this.parkingPosition.id ? `更新失敗(${response.message})` : `新增失敗(${response.message})`)
      }
      this.dismissLoading()
      this.$emit('close');
    },
    async onMove(event) {
      this.lat = event.lat()
      this.lng = event.lng()
    },
    onMapClicked(event) {
      this.lat = event.lat()
      this.lng = event.lng()
    }
  }
}
</script>

<style scoped>

</style>