<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-chip class="m-1" text-color="white"
                v-for="tag in deviceTags.filter(item=>{
                    if (item.includes('C:')) return false
                    if (item.includes('F:')) return false
                    if (item.includes('.hide')) return false
                    return true
                  })"
                :key="tag"
                :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'"
                @click="remove(tag)">
          {{ tag.split('.')[0] }}
        </v-chip>
      </v-col>
    </v-row>
    <v-divider/>
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="p-0" expand-icon="arrow_drop_down">
          <v-form ref="form" @submit.prevent>
            <v-text-field class="me-3"
                          label="新標籤"
                          v-model="word"
                          append-outer-icon="ios_share"
                          :rules="wordRules"
                          @click:append-outer="addWord"
            />
          </v-form>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip class="m-1" text-color="white"
                  v-for="tag in defTag.filter(tag=>!deviceTags.includes(tag))"
                  :key="tag"
                  :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'"
                  @click="add(tag)">
            {{ tag.split('.')[0] }}
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save">儲存</v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {mainStore, useTagStore} from "@/Store";
import {updateTags} from "@/Api-parking-pole";

export default {
  name: "ParkingPoleTagForm",
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    deviceId: String | Number,
    tags: Array
  },
  computed: {
    ...mapState(useTagStore, {defTag: 'tags'}),
  },
  data() {
    return {
      deviceTags: [...this.tags],
      word: '',
      wordRules: [
        v => !!v || '必填',
        v => !this.deviceTags.includes(v) || '重複',
      ],
    }
  },
  methods: {
    ...mapActions(useTagStore, {'init': 'init', 'addTag': 'add'}),
    ...mapActions(mainStore, ['updateDeviceView']),
    addWord() {
      if (!this.$refs.form.validate()) return
      this.add(this.word)
      this.$refs.form.reset()
    },
    add(tag) {
      if (!tag) return
      this.deviceTags.push(tag)
      this.addTag(tag)
    },
    remove(tag) {
      this.deviceTags = this.deviceTags.filter(t => t !== tag)
    },
    async save() {
      let add = []
      this.deviceTags.forEach(tag => {
        if (!(this.tags ?? []).includes(tag)) {
          add.push(tag)
        }
      })
      let remove = []
      this.tags?.forEach(tag => {
        if (!this.deviceTags.includes(tag)) {
          remove.push(tag)
        }
      })
      await updateTags(this.deviceId, add, remove)
      //await this.updateDeviceView(this.device, {...this.device, deviceTags: this.deviceTags})
      this.$emit('close', this.deviceTags)
    }
  },
  mounted() {
    this.init("parking-pole")
  }
}
</script>

<style scoped>

</style>