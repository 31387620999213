<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form ref="form" @submit.prevent="send">
          <v-text-field v-model="keyword"
                        placeholder="車格編號、地址、地磁ID"
                        append-outer-icon="send"
                        @click:append-outer="send"
          />
        </v-form>
      </v-col>
      <v-col class="text-end d-flex align-items-center" cols="1">
        <v-menu bottom offset-y :disabled="loading">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="orange" v-bind="attrs" v-on="on" dark>批量</v-btn>
          </template>
          <v-list>
            <v-list-item
                v-show="(firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                @click="selectDeviceSetting=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
              <v-list-item-content>
                <v-list-item-title class="text-primary">批量設定</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-show="(firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                @click="selectDeviceUpgrade=devices.filter(device => device.selected && device.deviceId).map(device => {
                    return {deviceId:device.deviceId, firmwareVersion:device.firmwareVersion, bid:device.bid ?? 17}
                  })">
              <v-list-item-content>
                <v-list-item-title class="cyan--text">批量升級</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-menu bottom offset-x left>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on"
                             v-if="organizationEnable"
                             v-show="(firstPermissions & 0b000000000000000000100000000000) === 0b000000000000000000100000000000">
                  <v-list-item-content>
                    <v-list-item-title class="orange--text">批量編輯組織、場域</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list dense>
                <template v-for="organization in organizations">
                  <span :key="organization.id">
                    <v-menu bottom offset-x left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{ organization.name }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-list dense>
                        <v-list-item @click="selectedDeviceChangeOrganization(organization)">
                          <v-list-item-content>
                            <v-list-item-title>無場域</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <template v-for="district in organization.districts">
                          <span :key="district.id">
                            <v-list-item @click="selectedDeviceChangeDistrict(district)">
                              <v-list-item-content>
                                <v-list-item-title>{{ district.name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="m-0"/>
                          </span>
                        </template>
                      </v-list>
                    </v-menu>
                    <v-divider class="m-0"/>
                  </span>
                </template>
              </v-list>
            </v-menu>
            <v-list-item
                v-show="(firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                @click="selectDeviceAddTag=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text">批量新增標籤</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-show="(firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                @click="selectDeviceRemoveTag=devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)">
              <v-list-item-content>
                <v-list-item-title class="green--text">批量移除標籤</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="devices" :options="options">
      <template slot="h__selected">
        <v-switch inset @change="selectAll($event)"/>
      </template>
      <template slot="selected" slot-scope="{row}">
        <div>
          <v-btn icon v-show="!!row.selected===false" @click="selectDevice(row,true)">
            <v-icon>check_box_outline_blank</v-icon>
          </v-btn>
          <v-btn icon v-show="!!row.selected===true" @click="selectDevice(row,false)">
            <v-icon>check_box</v-icon>
          </v-btn>
        </div>
      </template>
      <template slot="deviceId" slot-scope="{row}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ row.deviceId ?? row.parkingPoleId }}</span>
          </template>
          <v-chip-group>
            <v-chip class="m-1" text-color="white"
                    v-for="tag in (row.deviceTags??[]).filter(item=>{
                      if (item.includes('C:')) return false
                      if (item.includes('F:')) return false
                      if (item.includes('.hide')) return false
                      return true
                    })"
                    :key="tag"
                    :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'">
              {{ tag.split('.')[0] }}
            </v-chip>
          </v-chip-group>
        </v-tooltip>
      </template>
      <template slot="organization" slot-scope="{row}">
        {{ (row.deviceTags ?? []).find(tag => tag.includes('C:'))?.substring(2) }}
      </template>
      <template slot="field" slot-scope="{row}">
        {{ (row.deviceTags ?? []).find(tag => tag.includes('F:'))?.substring(2) }}
      </template>
      <template slot="deviceTags" slot-scope="{row}">
        <span v-if="!row.deviceTags"></span>
        <span class="text-danger" v-else-if="(row.deviceTags??[]).join().includes('廢品')">廢品</span>
        <span class="text-warning" v-else-if="(row.deviceTags??[]).join().includes('維修')">維修</span>
        <span v-else-if="(row.deviceTags??[]).join().includes('E:')">
          <span v-for="tag in row.deviceTags.filter(tag=>tag.includes('E:'))" v-bind:key="tag">
            <div class="text-danger" v-if="tag.includes('E:DISCONNECTED')">斷線異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:LOW_POWER')">低電量異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:MAGNETIC')">磁場異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:INFRARED')">紅外異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:BOR')">復位異常</div>
            <div class="text-danger" v-else-if="tag.includes('E:COVER')">遮蔽異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:INIT')">初始化異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:RSRP')">訊號異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:SINR')">信噪比異常</div>
            <div class="text-warning" v-else-if="tag.includes('E:SIM')">SIM卡異常</div>
            <div class="text-success" v-else-if="tag.includes('E:LONG_PARKING')">疑似長時間停車</div>
            <div class="text-success" v-else-if="tag.includes('E:NETWORK')">網路異常</div>
            <div class="text-success" v-else>{{ tag.replace('E:', '').replace('.hide', '') }}</div>
          </span>
        </span>
        <span v-else-if="row.deviceTags?.length===0"></span>
        <span class="text-success" v-else>正常</span>
      </template>
      <span slot="changeStarted" slot-scope="{row}">
        <p class="primary--text" v-if="row.changeStarted === 0">完成</p>
        <p class="amber--text" v-else-if="row.changeStarted === 1">待變更</p>
        <p v-else></p>
      </span>
      <span slot="updateFirmwareStarted" slot-scope="{row}">
        <p class="primary--text" v-if="row.updateFirmwareStarted === 0">完成</p>
        <p class="purple--text" v-else-if="row.updateFirmwareStarted === 1">待升級</p>
        <p class="deep-orange--text" v-else-if="row.updateFirmwareStarted === 2">升級中</p>
        <p v-else></p>
      </span>
      <span slot="isDelete" slot-scope="{row}" :class="row.isDelete ? 'red--text':'teal--text'">
        <p>{{ row.isDelete ? '是' : '否' }}</p>
      </span>
      <span slot="lastStatus.cp" slot-scope="{row}" :class="(row.lastStatus?.cp ?? 0) >= 50 ? 'red--text':'teal--text'">
        <p v-show="row.lastStatus">
          {{ (row.lastStatus?.cp ?? 0) >= 50 ? '是' : '否' }}
        </p>
      </span>
      <span slot="lastStatus.batteryVoltage" slot-scope="{row}"
            :class="(row.lastStatus?.batteryVoltage ?? 0) < 3300 ? 'red--text':'teal--text'">
        <p v-show="row.lastStatus">
          {{ (row.lastStatus?.batteryVoltage ?? 0) / 1000 }}V
        </p>
      </span>
      <span slot="lastStatus.changeTime" slot-scope="{row}">
          <p v-show="row.lastStatus">
            {{ row.lastStatus?.changeTime ? $moment(row.lastStatus.changeTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
          </p>
      </span>
      <span slot="lastStatus.uploadTime" slot-scope="{row}">
        <p v-show="row.lastStatus">
          {{ row.lastStatus?.uploadTime ? $moment(row.lastStatus.uploadTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
        </p>
      </span>
      <template slot="action" slot-scope="{row}">
        <v-menu bottom left offset-y v-if="['ROOT', 'MANAGER','STAFF','MEMBER'].includes(userRole)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">操作</v-btn>
          </template>
          <v-card>
            <v-row>
              <v-col>
                <v-list dense>
                  <v-list-item
                      v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                      v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000001000) === 0b000000000000000000000000001000"
                      @click="selectDeviceStatus=row">
                    <v-list-item-content>
                      <v-list-item-title class="text-primary">解析數據</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                      v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000000100) === 0b000000000000000000000000000100"
                      @click="selectDeviceChangeLog=row">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">車位變換紀錄</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                      v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000000000010000) === 0b000000000000000000000000010000"
                      @click="selectDeviceUnmoralLog=row">
                    <v-list-item-content>
                      <v-list-item-title class="red--text">異常紀錄</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
                <v-list dense>
                  <v-list-item
                      v-if="vehicleDetectorEnable"
                      v-show="row.deviceId && (firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                      @click="selectDeviceSetting=[row.deviceId]">
                    <v-list-item-content>
                      <v-list-item-title class="text-primary">設定</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="parkingPoleLuciferEnable"
                      v-show="row.parkingPoleId && (firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                      @click="selectPoleSetting=[row.parkingPoleId]">
                    <v-list-item-content>
                      <v-list-item-title class="text-primary">設定</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="vehicleDetectorEnable"
                      v-show="row.deviceId && (firstPermissions & 0b000000000000000000000001000000) === 0b000000000000000000000001000000"
                      @click="selectDeviceNet=row">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">網路數據</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="vehicleDetectorEnable"
                      v-show="row.deviceId && (firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                      @click="selectDeviceUpgrade=[{deviceId:row.deviceId, firmwareVersion:row.firmwareVersion, bid:row.bid ?? 17}]">
                    <v-list-item-content>
                      <v-list-item-title class="cyan--text">升級韌體</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="vehicleDetectorEnable || parkingPoleLuciferEnable"
                      v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                      @click="selectDeviceTag=row">
                    <v-list-item-content>
                      <v-list-item-title class="purple--text">標籤管理</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
                <v-list dense>
                  <v-list-item
                      v-if="positionEnable"
                      v-show="row.id && row.deviceId && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                      @click="selectParkingPositionBingDevice=row">
                    <v-list-item-content>
                      <v-list-item-title class="text-danger">解除綁定</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-if="positionEnable"
                      v-show="row.id && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                      @click="selectParkingPositionUpdate=row">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">更新停車格</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-menu bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item
                          v-if="organizationEnable && (positionEnable ||parkingPoleLuciferEnable)"
                          v-show="(row.deviceId || row.parkingPoleId) && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                          v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">編輯組織、場域</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-list dense>
                      <template v-for="organization in organizations">
                        <span :key="organization.id">
                          <v-menu bottom offset-x left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-content>
                                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <v-list dense>
                              <v-list-item @click="onDeviceChangeOrganizationClicked(row, organization)">
                                <v-list-item-content>
                                  <v-list-item-title>無場域</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-for="district in organization.districts">
                                <span :key="district.id">
                                  <v-list-item @click="onDeviceChangeFieldClicked(row, district)">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ district.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="m-0"/>
                                </span>
                              </template>
                            </v-list>
                          </v-menu>
                          <v-divider class="m-0"/>
                        </span>
                      </template>
                    </v-list>
                  </v-menu>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
        <v-btn color="primary" v-else
               v-show="row.deviceId && (firstPermissions & 0b000000000000000000000000000100) === 0b000000000000000000000000000100"
               @click="selectDeviceChangeLog=row">車位變換紀錄
        </v-btn>
      </template>
    </v-client-table>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceStatus"
              @click:outside="selectDeviceStatus = undefined">
      <v-card v-if="!!selectDeviceStatus">
        <v-card-title>
          <div class="me-1">{{ selectDeviceStatus.address }}</div>
          <div class="me-3">{{ selectDeviceStatus.name }}</div>
          <div class="me-3" v-show="selectDeviceStatus.deviceId">({{ selectDeviceStatus.deviceId }})</div>
          設備上傳數據
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceStatus = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceStatusFrom :device-id="selectDeviceStatus.deviceId"
                            v-if="selectDeviceStatus.deviceId"/>
          <ParkingPoleStatusFrom :device-id="selectDeviceStatus.parkingPoleId"
                                 v-else-if="selectDeviceStatus.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceChangeLog"
              @click:outside="selectDeviceChangeLog = undefined">
      <v-card v-if="!!selectDeviceChangeLog">
        <v-card-title>
          <div class="me-1">{{ selectDeviceChangeLog.address }}</div>
          <div class="me-3">{{ selectDeviceChangeLog.name }}</div>
          <div class="me-3" v-show="selectDeviceChangeLog.deviceId">({{ selectDeviceChangeLog.deviceId }})</div>
          車位變換紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceChangeLog = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <ChangeLogFrom :device-id="selectDeviceChangeLog.deviceId"
                         v-if="selectDeviceChangeLog.deviceId"/>
          <ParkingPoleChangeLogFrom :device-id="selectDeviceChangeLog.parkingPoleId"
                                    v-else-if="selectDeviceChangeLog.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceUnmoralLog"
              @click:outside="selectDeviceUnmoralLog = undefined">
      <v-card v-if="!!selectDeviceUnmoralLog">
        <v-card-title>
          <div class="me-1">{{ selectDeviceUnmoralLog.address }}</div>
          <div class="me-1">{{ selectDeviceUnmoralLog.name }}</div>
          <div class="me-1" v-show="selectDeviceUnmoralLog.deviceId">({{ selectDeviceUnmoralLog.deviceId }})</div>
          異常紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUnmoralLog = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <UnmoralLogFrom :device-id="selectDeviceUnmoralLog.deviceId"
                          v-if="selectDeviceUnmoralLog.deviceId"/>
          <ParkingPoleUnmoralLogFrom :device-id="selectDeviceUnmoralLog.parkingPoleId"
                                     v-else-if="selectDeviceUnmoralLog.parkingPoleId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceSetting || selectPoleSetting"
              @click:outside="selectDeviceSetting = undefined;selectPoleSetting= undefined">
      <v-card v-if="selectDeviceSetting || selectPoleSetting">
        <v-card-title>
          <p>設定</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceSetting = undefined;selectPoleSetting= undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <SettingFrom :device-id="selectDeviceSetting" v-if="selectDeviceSetting"
                       @close="selectDeviceSetting = undefined"/>
          <ParkingPoleSettingFrom :device-id="selectPoleSetting" v-else-if="selectPoleSetting"
                                  @close="selectPoleSetting= undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceUpgrade"
              @click:outside="selectDeviceUpgrade = undefined">
      <v-card v-if="!!selectDeviceUpgrade">
        <v-card-title>
          <p>升級</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUpgrade = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceUpgradeFrom :devices="selectDeviceUpgrade"
                             @close="selectDeviceUpgrade = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceTag"
              @click:outside="selectDeviceTag = undefined">
      <v-card v-if="!!selectDeviceTag">
        <v-card-title>
          <p>標籤管理</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagForm :device-id="selectDeviceTag.deviceId"
                         :tags="selectDeviceTag.deviceTags"
                         v-if="selectDeviceTag.deviceId"
                         @close="closeDeviceTag"/>
          <ParkingPoleTagForm :device-id="selectDeviceTag.parkingPoleId"
                              :tags="selectDeviceTag.deviceTags"
                              v-else-if="selectDeviceTag.parkingPoleId"
                              @close="closeDeviceTag"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceAddTag"
              @click:outside="selectDeviceAddTag = undefined">
      <v-card v-if="!!selectDeviceAddTag">
        <v-card-title>
          <p>批量新增標籤</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceAddTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagArrayAddTagForm :device-id="selectDeviceAddTag"
                                    @close="selectDeviceAddTag = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceRemoveTag"
              @click:outside="selectDeviceRemoveTag = undefined">
      <v-card v-if="!!selectDeviceRemoveTag">
        <v-card-title>
          <p>批量移除標籤</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceRemoveTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagArrayRemoveTagForm :device-id="selectDeviceRemoveTag"
                                       @close="selectDeviceRemoveTag = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectParkingPositionBingDevice"
              @click:outside="selectParkingPositionBingDevice = undefined">
      <v-card v-if="!!selectParkingPositionBingDevice">
        <v-card-title>
          <p>解除綁定</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectParkingPositionBingDevice = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <p>確定解除綁定 {{ selectParkingPositionBingDevice.deviceId }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="unbind">確定</v-btn>
          <v-btn color="danger" @click="selectParkingPositionBingDevice = undefined">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectParkingPositionUpdate"
              @click:outside="selectParkingPositionUpdate = undefined">
      <v-card v-if="!!selectParkingPositionUpdate">
        <v-card-title>
          <p v-if="!selectParkingPositionUpdate.id">新增停車格</p>
          <p v-else>更新停車格</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectParkingPositionUpdate = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <PositionCreateOrUpdateFrom :parking-position="selectParkingPositionUpdate"
                                      @close="selectParkingPositionUpdate = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceNet"
              @click:outside="selectDeviceNet = undefined">
      <v-card v-if="!!selectDeviceNet">
        <v-card-title>
          <p>網路數據</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceNet = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceNetFrom :device-id="selectDeviceNet.deviceId"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";
import {getPositionsKeyword, unbindPosition, deviceBindField, deviceBindOrganization} from "@/Api";
import {deviceBindField as poleBindOField, deviceBindOrganization as poleBindOrganization} from "@/Api-parking-pole";
import DeviceUpgradeFrom from "@/components/DeviceUpgradeFrom";
import DeviceStatusFrom from "@/components/DeviceStatusFrom";
import ChangeLogFrom from "@/components/ChangeLogFrom";
import UnmoralLogFrom from "@/components/UnmoralLogFrom";
import SettingFrom from "@/components/SettingFrom";
import PositionCreateOrUpdateFrom from "@/components/PositionCreateOrUpdateFrom";
import DeviceTagArrayRemoveTagForm from "@/components/DeviceTagArrayRemoveTagForm";
import DeviceTagArrayAddTagForm from "@/components/DeviceTagArrayAddTagForm";
import DeviceTagForm from "@/components/DeviceTagForm";
import DeviceNetFrom from "@/components/DeviceNetFrom";
import ParkingPoleStatusFrom from "@/components/parking-pole/ParkingPoleStatusFrom";
import ParkingPoleChangeLogFrom from "@/components/parking-pole/ParkingPoleChangeLogFrom";
import ParkingPoleUnmoralLogFrom from "@/components/parking-pole/ParkingPoleUnmoralLogFrom";
import ParkingPoleSettingFrom from "@/components/parking-pole/ParkingPoleSettingFrom";
import ParkingPoleTagForm from "@/components/parking-pole/ParkingPoleTagForm.vue";

export default {
  name: "KeywordSearchPositionFrom",
  components: {
    DeviceTagForm,
    DeviceTagArrayAddTagForm,
    DeviceTagArrayRemoveTagForm,
    PositionCreateOrUpdateFrom,
    DeviceUpgradeFrom,
    DeviceStatusFrom,
    ChangeLogFrom,
    UnmoralLogFrom,
    SettingFrom,
    DeviceNetFrom,
    ParkingPoleStatusFrom,
    ParkingPoleChangeLogFrom,
    ParkingPoleUnmoralLogFrom,
    ParkingPoleSettingFrom,
    ParkingPoleTagForm,
  },
  computed: {
    ...mapState(mainStore, ['userRole', 'firstPermissions']),
    ...mapState(mainStore, ['organizations']),
    ...mapState(mainStore, ['oauthEnable', 'organizationEnable', 'positionEnable', 'vehicleDetectorEnable', 'parkingPoleLuciferEnable']),
    ...mapState(mainStore, ['loading']),
  },
  data() {
    return {
      keyword: '',
      devices: [],
      columns: ['selected', 'deviceId', 'organization', 'field', 'address', 'name', 'deviceTags', 'changeStarted', 'updateFirmwareStarted', 'isDelete', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'action'],
      options: {
        headings: {
          selected: '',
          organization: '組織',
          field: '場域',
          deviceId: '設備ID',
          address: '地址',
          name: '車格編號',
          deviceTags: '狀態',
          changeStarted:'設備設定狀態',
          updateFirmwareStarted:'設備升級狀態',
          isDelete: '刪除',
          'lastStatus.cp': '是否停車',
          'lastStatus.batteryVoltage': '電量',
          'lastStatus.changeTime': '資料上傳時間',
          'lastStatus.uploadTime': '資料接收時間',
          action: '操作'
        },
        orderBy: {ascending: false, column: 'deviceId'},
        sortable: ['deviceId', 'address', 'name', 'deviceTags', 'changeStarted', 'updateFirmwareStarted', 'isDelete', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.changeTime', 'lastStatus.uploadTime'],
        filterable: false,
      },
      selectedAll: false,
      selectDeviceStatus: undefined,
      selectDeviceChangeLog: undefined,
      selectDeviceUnmoralLog: undefined,
      selectDeviceSetting: undefined,
      selectPoleSetting: undefined,
      selectDeviceUpgrade: undefined,
      selectDeviceTag: undefined,
      selectDeviceAddTag: undefined,
      selectDeviceRemoveTag: undefined,
      selectParkingPositionBingDevice: undefined,
      selectParkingPositionUpdate: undefined,
      selectDeviceNet: undefined,
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['onDeviceChangeOrganization', 'onDeviceChangeField', 'updateDeviceView']),
    selectAll(value) {
      this.devices.forEach(item => {
        item.selected = value
      })
      this.devices = [...this.devices]
    },
    async send() {
      if (!this.keyword) return
      this.showLoading()
      this.devices = await getPositionsKeyword(this.keyword)
      this.dismissLoading()
    },
    async unbind() {
      this.showLoading()
      let response = await unbindPosition(this.selectParkingPositionBingDevice.id)
      this.selectParkingPositionBingDevice = undefined
      this.dismissLoading()
      if (response) {
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      await this.send()
    },
    selectDevice(device, value) {
      this.devices.filter(item => (item.deviceId ?? item.id) === (device.deviceId ?? device.id)).forEach(item => {
        item.selected = value
      })
      this.devices = [...this.devices]
    },
    async onDeviceChangeOrganizationClicked(device, organization) {
      this.showLoading()
      let response
      if (device.deviceId) {
        response = await deviceBindOrganization(device.deviceId, organization.appId)
      } else if (device.parkingPoleId) {
        response = await poleBindOrganization(device.parkingPoleId, organization.appId)
      }
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.send()
      } else {
        this.error(response.message)
      }
    },
    async onDeviceChangeFieldClicked(device, district) {
      this.showLoading()
      let response
      if (device.deviceId) {
        response = await deviceBindField(device.deviceId, district.appId)
      } else if (device.parkingPoleId) {
        response = await poleBindOField(device.parkingPoleId, district.appId)
      }
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.send()
      } else {
        this.error(response.message)
      }
    },
    async selectedDeviceChangeOrganization(organization) {
      this.showLoading()
      let list = this.devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)
      let response = await deviceBindOrganization(list, organization.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.send()
      } else {
        this.error(response.message)
      }
    },
    async selectedDeviceChangeDistrict(district) {
      this.showLoading()
      let list = this.devices.filter(device => device.selected && device.deviceId).map(device => device.deviceId)
      let response = await deviceBindField(list, district.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.send()
      } else {
        this.error(response.message)
      }
    },
    async closeDeviceTag(deviceTags) {
      this.devices = await this.updateDeviceView(this.selectDeviceTag, {
        ...this.selectDeviceTag,
        deviceTags
      }, this.devices)
    }
  }
}
</script>

<style scoped>

</style>