import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import {createPinia, PiniaVuePlugin} from 'pinia'
import router from '@/router'
import * as VueGoogleMaps from 'vue2-google-maps'
import {ClientTable} from 'vue-tables-2'
import DatePicker from 'vue2-datepicker'

Vue.prototype.$moment = require('moment')

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import "@/assets/main.scss"
import "@/assets/main.css"
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import 'vue-loading-overlay/dist/vue-loading.css';
import App from "@/App";

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
import {library, config, dom} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

Vue.use(ClientTable, {
    sortIcon: {base: 'fas ms-5', is: 'fa-sort ms-5', up: 'fa-sort-up ms-5', down: 'fa-sort-down ms-5'},
    perPageValues: [10]
}, false, 'bootstrap3', 'default')
library.add(fas, fab, far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
config.autoReplaceSvg = 'nest'
dom.watch()

Vue.component('date-picker', DatePicker)
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB9wZY7H3eJ2hlThlxs01psC9ExlZx_GOs',
        //libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    //autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
})

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia)

new Vue({
    render: h => h(App),
    router,
    vuetify,
}).$mount('#app')
