<template>
  <div>
    <div id="reader" width="600px"/>
    <p class="text-center">{{ result }}</p>
    <p class="text-center">{{ profile }}</p>
    <p class="text-center">{{ profile }}</p>
  </div>
</template>

<script>
import {Html5Qrcode} from "html5-qrcode";
import liff from "@line/liff";

export default {
  name: "QrcodeScannerFrom",
  data() {
    return {
      result: '',
      profile: {},
    }
  },
  methods: {
    onScanSuccess(decodedText) {
      this.result = decodedText
    },
    onScanFailure() {
    },
    getProfileSuccess(profile) {
      this.profile = profile
    },
    getProfileFailure() {
    }
  },
  mounted() {
    const html5QrCode = new Html5Qrcode(/* element id */ "reader");
    Html5Qrcode.getCameras().then(devices => {
      let cameraId = undefined
      if (devices && devices.length > 1) {
        cameraId = devices[1].id;
      } else if (devices && devices.length) {
        cameraId = devices[0].id;
      }
      if (!cameraId) return
      html5QrCode.start(
          cameraId,
          {
            fps: 10,    // Optional, frame per seconds for qr code scanning
            qrbox: {width: 250, height: 250}  // Optional, if you want bounded box UI
          }, this.onScanSuccess, this.onScanFailure)
          // eslint-disable-next-line no-unused-vars
          .catch(_ => {
          })
      // eslint-disable-next-line no-unused-vars
    }).catch(_ => {
    })
    liff.init({
      liffId: process.env.VUE_APP_LIFFID, // Use own liffId
    }).then(() => {
      // Start to use liff's api
      liff.getProfile()
          .then(this.getProfileSuccess)
          .catch(this.getProfileFailure)
    }).catch(this.getProfileFailure);

  }
}
</script>

<style scoped>

</style>