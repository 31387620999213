<template>
  <v-container fluid>
    <v-form ref="passwordForm" @submit.prevent>
      <v-text-field class="col"
                    label="新密碼"
                    v-model="newPassword"
                    :append-outer-icon="'vpn_key'"
                    :rules="newPasswordRules"
                    @click:append-outer="randomPassword"
      />
    </v-form>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary"
             @click="updatePassword(userId,newPassword)">
        儲存
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import {mapActions} from "pinia";
import {toast, useLoadingStore} from "@/Store";
import {adminPatchUser, patchUserBySelf} from "@/Api";

export default {
  name: "UpdatePasswordFrom",
  props: {
    userId: String
  },
  data() {
    return {
      newPassword: '',
      newPasswordRules: [
        v => !!v || '必填'
      ],
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    randomPassword() {
      this.newPassword = `${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}${parseInt(Math.random() * 10)}`
    },
    async updatePassword(userId, password) {
      console.log(`userId = ${userId}`)
      console.log(`password = ${password}`)
      if (!this.$refs.passwordForm.validate()) return
      this.showLoading()
      let response;
      if (userId) {
        response = await adminPatchUser({userId, password})
      } else {
        response = await patchUserBySelf({password})
      }
      this.$refs.passwordForm.reset()
      if (response) {
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>