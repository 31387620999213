<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="10">
        <v-row>
          <v-col cols="12" sm="9">
            <v-form @submit.prevent="search">
              <v-text-field v-model="deviceId"
                            placeholder="地磁ID"
                            dense
                            outlined/>
            </v-form>
          </v-col>
          <v-col cols="12" sm="3">
            <v-form @submit.prevent="search">
              <v-select
                  v-model="enabled"
                  placeholder="是否啟用"
                  dense
                  outlined
                  :items="[
                {text:'啟用',value:1},
                {text:'未啟用',value:0}
            ]"
              />
            </v-form>
          </v-col>
          <v-col cols="12" sm="9">
            <v-form @submit.prevent="search">
              <v-text-field v-model="tags"
                            placeholder="標籤"
                            dense
                            outlined/>
            </v-form>
          </v-col>
          <v-col cols="12" sm="3" v-if="organizationEnable">
            <v-btn icon v-show="unbinding!==true" @click="unbinding=true">
              <v-icon>check_box_outline_blank</v-icon>
            </v-btn>
            <v-btn icon v-show="unbinding===true" @click="unbinding=false">
              <v-icon>check_box</v-icon>
            </v-btn>
            搜尋未綁定地磁
          </v-col>
        </v-row>
      </v-col>
      <v-col class="text-end" cols="12" sm="2">
        <v-btn-toggle>
          <v-btn color="primary" @click="search" right>搜尋</v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="orange" v-bind="attrs" v-on="on" dark>批量</v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-show="(firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                  @click="selectDeviceSetting=devices.filter(device => device.selected).map(device => device.id)">
                <v-list-item-content>
                  <v-list-item-title class="text-primary">批量設定</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  v-show="(firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                  @click="selectDeviceUpgrade=devices.filter(device => device.selected).map(device => {
                    return {deviceId:device.id, firmwareVersion:device.firmwareVersion, bid:device.bid ?? 17}
                  })">
                <v-list-item-content>
                  <v-list-item-title class="cyan--text">批量升級</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-menu bottom offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on"
                               v-show="(firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">編輯啟用</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list dense>
                  <v-list-item @click="selectedUpdateDeviceEnabled(1)">
                    <v-list-item-content>
                      <v-list-item-title class="text-success">啟用</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="m-0"/>
                  <v-list-item @click="selectedUpdateDeviceEnabled(0)">
                    <v-list-item-content>
                      <v-list-item-title class="text-danger">不啟用</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu bottom offset-x left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on"
                               v-if="organizationEnable"
                               v-show="(firstPermissions & 0b000000000000000000100000000000) === 0b000000000000000000100000000000">
                    <v-list-item-content>
                      <v-list-item-title class="orange--text">批量編輯組織、場域</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list dense>
                  <template v-for="organization in organizations">
                      <span :key="organization.id">
                        <v-menu bottom offset-x left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-content>
                                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <v-list dense>
                              <v-list-item @click="selectedDeviceChangeOrganization(organization)">
                                <v-list-item-content>
                                  <v-list-item-title>無場域</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-for="district in organization.districts">
                                <span :key="district.id">
                                  <v-list-item @click="selectedDeviceChangDistrict(district)">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ district.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="m-0"/>
                                </span>
                              </template>
                            </v-list>
                          </v-menu>
                        <v-divider class="m-0"/>
                      </span>
                  </template>
                </v-list>
              </v-menu>
            </v-list>
          </v-menu>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-progress-linear color="main" v-if="loading" indeterminate/>
    <v-divider v-else/>
    <v-client-table :columns="organizationEnable?columns2:columns" :data="devices" :options="options"
                    @pagination="onLoaded($event)">
      <template slot="h__selected">
        <v-switch inset @change="selectAll($event)"/>
      </template>
      <template slot="selected" slot-scope="{row}">
        <div>
          <v-btn icon v-show="!!row.selected===false" @click="selectDevice(row.id,true)">
            <v-icon>check_box_outline_blank</v-icon>
          </v-btn>
          <v-btn icon v-show="!!row.selected===true" @click="selectDevice(row.id,false)">
            <v-icon>check_box</v-icon>
          </v-btn>
        </div>
      </template>
      <template slot="id" slot-scope="{row}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ row.id }}</span>
          </template>
          <v-chip-group>
            <v-chip class="ml-1 mr-1" text-color="white"
                    v-for="tag in (row.tags??[])" :key="tag"
                    :color="tag.includes('.')?`${tag.split('.')[1]}`:'grey'">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-tooltip>
      </template>
      <template slot="organization" slot-scope="{row}">
        <span v-for="appId in (row.tags ?? []).filter(tag => tag.includes('C:')).map(tag => tag.substring(2))"
              :key="appId">
          {{
            organizations.find(o => o.appId === appId)?.name ?? appId
          }}
        </span>
      </template>
      <template slot="district" slot-scope="{row}">
        <span v-for="appId in (row.tags ?? []).filter(tag => tag.includes('F:')).map(tag => tag.substring(2))"
              :key="appId">
          {{
            districts.find(o => o.appId === appId)?.name ?? appId
          }}
        </span>
      </template>
      <span slot="lastStatus.cp" slot-scope="{row}" :class="(row.lastStatus?.cp ?? 0) >= 50 ? 'red--text':'teal--text'">
        <p v-show="row.lastStatus">
          <span v-if="(row.lastStatus?.cp ?? 0) < 40">無車</span>
          <span v-else-if="40 <= (row.lastStatus?.cp ?? 0) && (row.lastStatus?.cp ?? 0)  < 50">
            <span v-if="userRole==='USER'">疑似無車</span>
            <span v-else>無車</span>
          </span>
          <span v-else-if="50 <= (row.lastStatus?.cp ?? 0) && (row.lastStatus?.cp ?? 0)  < 60">
            <span v-if="userRole==='USER'">疑似有車</span>
            <span v-else>有車</span>
          </span>
          <span v-else>有車</span>
        </p>
      </span>
      <span slot="lastStatus.batteryVoltage" slot-scope="{row}"
            :class="((row.lastStatus?.batteryVoltage ?? 0) < 3300 || (row.lastStatus?.batteryVoltage ?? 0) > 10000) ? 'red--text':'teal--text'">
        <span v-if="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
        <p v-show="row.lastStatus">
          {{ (row.lastStatus?.batteryVoltage ?? 0) / 1000 }}V
        </p></span>
        <span v-else>
          <p v-if="!row.lastStatus?.batteryVoltage"></p>
          <p v-else-if="(row.lastStatus?.batteryVoltage ?? 0) > 10000">電壓異常（{{
              (row.lastStatus?.batteryVoltage ?? 0) / 1000
            }}V）</p>
          <p v-else-if="(row.lastStatus?.batteryVoltage ?? 0) < 3300">低電量（{{
              (row.lastStatus?.batteryVoltage ?? 0) / 1000
            }}V）</p>
          <p v-else>電量正常（{{ (row.lastStatus?.batteryVoltage ?? 0) / 1000 }}V）</p>
        </span>
      </span>
      <span slot="lastStatus.changeTime" slot-scope="{row}">
          <p v-show="row.lastStatus">
            {{ row.lastStatus?.changeTime ? $moment(row.lastStatus.changeTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
          </p>
      </span>
      <span slot="lastStatus.uploadTime" slot-scope="{row}">
        <p v-show="row.lastStatus">
          {{ row.lastStatus?.uploadTime ? $moment(row.lastStatus.uploadTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
        </p>
      </span>
      <span slot="uploadTime" slot-scope="{row}">
          {{ row.lastStatus?.uploadTime ? $moment(row.lastStatus.uploadTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
      </span>
      <template slot="action" slot-scope="{row}">
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">操作</v-btn>
          </template>
          <v-card>
            <v-row>
              <v-col>
                <v-list dense>
                  <v-list-item @click="selectDeviceStatus=row">
                    <v-list-item-content>
                      <v-list-item-title class="text-primary">解析數據</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="selectDeviceChangeLog=row">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">車位變換紀錄</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="selectDeviceUnmoralLog=row">
                    <v-list-item-content>
                      <v-list-item-title class="red--text">異常紀錄</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list dense>
                  <v-list-item
                      v-show="(firstPermissions & 0b000000000000000000000010000000) === 0b000000000000000000000010000000"
                      @click="selectDeviceSetting=[row.id]">
                    <v-list-item-content>
                      <v-list-item-title class="text-primary">設定</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-show="(firstPermissions & 0b000000000000000000000001000000) === 0b000000000000000000000001000000"
                      @click="selectDeviceNet=row">
                    <v-list-item-content>
                      <v-list-item-title class="teal--text">網路數據</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-show="(firstPermissions & 0b000000000000000000000100000000) === 0b000000000000000000000100000000"
                      @click="selectDeviceUpgrade=[{deviceId:row.id, firmwareVersion:row.firmwareVersion, bid:row.bid ?? 17}]">
                    <v-list-item-content>
                      <v-list-item-title class="cyan--text">升級韌體</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-show="(firstPermissions & 0b000000000000000000001000000000) === 0b000000000000000000001000000000"
                      @click="selectDeviceTag=row">
                    <v-list-item-content>
                      <v-list-item-title class="purple--text">標籤管理</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list dense>
                  <v-menu bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on"
                                   v-if="organizationEnable"
                                   v-show="(firstPermissions & 0b000000000000000000100000000000) === 0b000000000000000000100000000000">
                        <v-list-item-content>
                          <v-list-item-title class="orange--text">批量編輯組織、場域</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-list dense>
                      <template v-for="organization in organizations">
                        <span :key="organization.id">
                          <v-menu bottom offset-x left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-content>
                                  <v-list-item-title>{{ organization.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <v-list dense>
                              <v-list-item @click="bindDeviceOrganization(row,organization)">
                                <v-list-item-content>
                                  <v-list-item-title>無場域</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <template v-for="district in organization.districts">
                                <span :key="district.id">
                                  <v-list-item @click="bindDeviceField(row,district)">
                                    <v-list-item-content>
                                      <v-list-item-title>{{ district.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="m-0"/>
                                </span>
                              </template>
                            </v-list>
                          </v-menu>
                          <v-divider class="m-0"/>
                        </span>
                      </template>
                    </v-list>
                  </v-menu>
                  <v-menu bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-content>
                          <v-list-item-title class="teal--text">編輯啟用</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-list dense>
                      <v-list-item @click="updateDeviceEnabled(row,1)">
                        <v-list-item-content>
                          <v-list-item-title class="text-success">啟用</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="m-0"/>
                      <v-list-item @click="updateDeviceEnabled(row,0)">
                        <v-list-item-content>
                          <v-list-item-title class="text-danger">不啟用</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </template>
    </v-client-table>
    <v-dialog content-class="h-100"
              scrollable
              :value="selectDeviceStatus"
              @click:outside="selectDeviceStatus=undefined">
      <v-card v-if="!!selectDeviceStatus">
        <v-card-title>
          <div class="me-3">{{ selectDeviceStatus.id }}</div>
          設備上傳數據
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceStatus=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceStatusFrom :device-id="`${selectDeviceStatus.id}`"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="h-100"
              scrollable
              :value="selectDeviceChangeLog"
              @click:outside="selectDeviceChangeLog=undefined">
      <v-card v-if="!!selectDeviceChangeLog">
        <v-card-title>
          <div class="me-3">{{ selectDeviceChangeLog.id }}</div>
          車位變換紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceChangeLog=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <ChangeLogFrom :device-id="`${selectDeviceChangeLog.id}`"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="h-100"
              scrollable
              :value="selectDeviceUnmoralLog"
              @click:outside="selectDeviceUnmoralLog=undefined">
      <v-card v-if="!!selectDeviceUnmoralLog">
        <v-card-title>
          <div class="me-1">{{ selectDeviceUnmoralLog.id }}</div>
          異常紀錄
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUnmoralLog=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <UnmoralLogFrom :device-id="`${selectDeviceUnmoralLog.id}`"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-big"
              scrollable
              :value="selectDeviceSetting"
              @click:outside="selectDeviceSetting = undefined">
      <v-card v-if="!!selectDeviceSetting">
        <v-card-title>
          <p>設定</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceSetting = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <SettingFrom :device-id="selectDeviceSetting"
                       @close="selectDeviceSetting = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceNet"
              @click:outside="selectDeviceNet = undefined">
      <v-card v-if="!!selectDeviceNet">
        <v-card-title>
          <p>網路數據</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceNet = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceNetFrom :device-id="selectDeviceNet.id"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content"
              scrollable
              :value="selectDeviceUpgrade"
              @click:outside="selectDeviceUpgrade = undefined">
      <v-card v-if="!!selectDeviceUpgrade">
        <v-card-title>
          <p>升級</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceUpgrade = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceUpgradeFrom :devices="selectDeviceUpgrade"
                             @close="selectDeviceUpgrade = undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="dialog-content-small"
              scrollable
              :value="selectDeviceTag"
              @click:outside="selectDeviceTag = undefined">
      <v-card v-if="!!selectDeviceTag">
        <v-card-title>
          <p>標籤管理</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceTag = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="h-100">
          <DeviceTagForm :device-id="selectDeviceTag.id"
                         :tags="selectDeviceTag.tags"
                         @close="closeDeviceTag"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {deviceBindField, deviceBindOrganization, getDevices, patchDeviceSetting} from "@/Api";
import UnmoralLogFrom from "@/components/UnmoralLogFrom";
import ChangeLogFrom from "@/components/ChangeLogFrom";
import DeviceStatusFrom from "@/components/DeviceStatusFrom";
import SettingFrom from "@/components/SettingFrom";
import DeviceUpgradeFrom from "@/components/DeviceUpgradeFrom";
import DeviceNetFrom from "@/components/DeviceNetFrom";
import DeviceTagForm from "@/components/DeviceTagForm";
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";

export default {
  name: "DeviceSearch",
  components: {
    DeviceStatusFrom,
    ChangeLogFrom,
    UnmoralLogFrom,
    SettingFrom,
    DeviceNetFrom,
    DeviceUpgradeFrom,
    DeviceTagForm
  },
  computed: {
    ...mapState(mainStore, ['userRole', 'firstPermissions']),
    ...mapState(mainStore, ['organizationEnable']),
    ...mapState(mainStore, ['organizations', 'districts', 'selectOrganizationAppId', 'selectDistrictAppId']),
    ...mapState(mainStore, ['updateDeviceView']),
    ...mapState(useLoadingStore, {loading: 'visibility'})
  },
  data() {
    return {
      deviceId: '',
      tags: undefined,
      enabled: undefined,
      unbinding: undefined,
      columns: ['selected', 'id', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'uploadTime', 'action'],
      columns2: ['selected', 'id', 'organization', 'district', 'lastStatus.cp', 'lastStatus.batteryVoltage', 'lastStatus.changeTime', 'lastStatus.uploadTime', 'uploadTime', 'action'],
      devices: [],
      options: {
        headings: {
          selected: '',
          id: '設備ID',
          organization: '組織',
          district: '場域',
          'lastStatus.cp': '是否停車',
          'lastStatus.batteryVoltage': '電量',
          'lastStatus.changeTime': '設備上傳時間',
          'lastStatus.uploadTime': '資料接收時間',
          uploadTime: '資料接收時間',
          action: '操作'
        },
        orderBy: {ascending: true, column: 'id'},
        sortable: ['id', 'uploadTime'],
        filterable: false,
      },
      params: {
        tags: undefined,
        enabled: undefined,
        unbinding: undefined,
      },
      selectDeviceStatus: undefined,
      selectDeviceChangeLog: undefined,
      selectDeviceUnmoralLog: undefined,
      selectDeviceSetting: undefined,
      selectDeviceNet: undefined,
      selectDeviceUpgrade: undefined,
      selectDeviceTag: undefined
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async search() {
      if (this.unbinding !== true && this.organizationEnable) {
        if (!this.selectOrganizationAppId) return
      }
      this.params = {
        deviceId: this.deviceId,
        tags: this.tags,
        enabled: this.enabled,
        unbinding: this.unbinding,
        organizationAppId: this.selectOrganizationAppId,
        districtAppId: this.selectDistrictAppId,
      }
      this.showLoading()

      this.devices = await getDevices({
        ...this.params, page: 0
      })
      this.dismissLoading()
    },
    async onLoaded(event) {
      if (parseInt(this.devices.length / 10) === 0) return
      this.showLoading()
      if (event === parseInt(this.devices.length / 10)) {
        let page = parseInt(this.devices.length / 100) + (this.devices.length % 100 > 0 ? 1 : 0)
        let list = await getDevices({...this.params, page})
        if (list.length > 0) {
          this.devices = [...this.devices, ...list]
        }
      }
      this.dismissLoading()
    },
    async updateDeviceEnabled(device, enabled) {
      this.showLoading()
      await patchDeviceSetting(device.id, {enabled})
      await this.search()
      this.dismissLoading()
    },
    async bindDeviceOrganization(device, organization) {
      this.showLoading()
      let response = await deviceBindOrganization(device.id, organization.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.search()
      } else {
        this.error(response.message)
      }
    },
    async bindDeviceField(device, district) {
      this.showLoading()
      let response = await deviceBindField(device.id, district.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.search()
      } else {
        this.error(response.message)
      }
    },
    async selectedUpdateDeviceEnabled(enabled) {
      this.showLoading()
      let list = this.devices.filter(device => device.selected).map(device => device.id)
      let response = await patchDeviceSetting(list, {enabled})
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.search()
      } else {
        this.error(response.message)
      }
    },
    async selectedDeviceChangeOrganization(organization) {
      this.showLoading()
      let list = this.devices.filter(device => device.selected).map(device => device.id)
      let response = await deviceBindOrganization(list, organization.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.search()
      } else {
        this.error(response.message)
      }
    },
    async selectedDeviceChangDistrict(district) {
      this.showLoading()
      let list = this.devices.filter(device => device.selected).map(device => device.id)
      let response = await deviceBindField(list, district.appId)
      this.dismissLoading()
      if (response.ok) {
        this.success('更新成功')
        await this.search()
      } else {
        this.error(response.message)
      }
    },
    selectAll(value) {
      this.devices.forEach(item => {
        item.selected = value
      })
      this.devices = [...this.devices]
    },
    selectDevice(deviceId, value) {
      this.devices.filter(item => item.id === deviceId).forEach(item => {
        item.selected = value
      })
      this.devices = [...this.devices]
      console.log(this.devices)
    },
    closeDeviceTag(tags) {
      this.devices.forEach(device => {
        if (device.id === this.selectDeviceTag.id) {
          device.tags = tags
        }
      })
      this.selectDeviceTag = undefined
    }
  }
}
</script>

<style scoped>

</style>