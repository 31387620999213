<template>
  <v-container fluid>
    <v-row>
      <v-col cols="1">
        <v-btn icon color="primary" @click="$router.back()">
          <v-icon>arrow_back_ios_new</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9">
        <v-autocomplete
            v-model="userId"
            item-text="username"
            item-value="id"
            :items="users.filter(user => !list.includes(user.id) )"
        />
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" @click="addUser" right>新增</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="users.filter(user => list.includes(user.id) )" :options="options">
      <template slot="action" slot-scope="{row}">
        <v-btn-toggle>
          <v-btn color="error" small outlined @click="deleteUser(row)">刪除</v-btn>
        </v-btn-toggle>
      </template>
    </v-client-table>
  </v-container>
</template>

<script>
import {
  getUsers,
  getUserIdsByOrganizationId,
  userBindOrganization,
  userUnbindOrganization
} from "@/Api";
import {mapActions, mapState} from "pinia";
import {mainStore, useLoadingStore} from "@/Store";

export default {
  name: "OrganizationAddUser",
  props: {
    organizationId: String,
  },
  computed: {
    ...mapState(useLoadingStore, ['visibility']),
    ...mapState(mainStore, {myUserId: 'userId'})
  },
  data() {
    return {
      userId: '',
      columns: ['username', 'action'],
      list: [],
      options: {
        headings: {
          username: '使用者名稱',
          action: '操作'
        },
        orderBy: {ascending: true, column: 'username'},
        sortable: ['username'],
        filterable: false,
      },
      users: [],
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    ...mapActions(mainStore, ['reloadOrganizations']),
    async loadUsers() {
      this.showLoading()
      this.users = await getUsers()
      this.dismissLoading()
    },
    async loadUsersByOrganizationId() {
      this.showLoading()
      this.list = await getUserIdsByOrganizationId(this.organizationId)
      this.dismissLoading()
    },
    async addUser() {
      this.showLoading()
      await userBindOrganization(this.userId, this.organizationId)
      await this.loadUsersByOrganizationId()
      if (this.userId === this.myUserId) {
        await this.reloadOrganizations()
      }
      this.dismissLoading()
    },
    async deleteUser(user) {
      this.showLoading()
      await userUnbindOrganization(user.id, this.organizationId)
      await this.loadUsersByOrganizationId()
      if (user.id === this.myUserId) {
        await this.reloadOrganizations()
      }
      this.dismissLoading()
    }
  },
  async mounted() {
    await this.loadUsers()
    await this.loadUsersByOrganizationId()
  }
}
</script>

<style scoped>

</style>