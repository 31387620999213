<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" class="text-end text-lg-h5">設備ID</v-col>
      <v-col cols="8" class="text-start text-lg-h5" v-if="title.length < 20">
        <span> {{ title }}</span>
        <span class="ms-2" v-show="imsi">
          <span v-if="(/^(46601|46603|46688)/.test(imsi))">（遠傳電信）</span>
          <span v-else-if="(/^(46697|46699|46693)/.test(imsi))">（台灣大哥大）</span>
          <span v-else-if="(/^(46611|46692)/.test(imsi))">（中華電信）</span>
          <span v-else></span>
        </span>
      </v-col>
      <v-col cols="8" class="text-start text-lg-h5" v-else>
        <v-menu content-class="max-h-50" bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ title }}...</span>
          </template>
          <v-card>
            <v-card-text class="overflow-scroll">
              <span>{{ deviceId.join(',') }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="text-end text-lg-h5">變更狀態</v-col>
      <v-chip-group class="col p-1">
        <v-chip color="light-green accent-4" v-show="!setting.changeStarted">已變更</v-chip>
        <v-chip color="amber" v-show="setting.changeStarted">待變更</v-chip>
        <v-chip color="purple accent-1" v-show="setting.updateFirmwareStarted===1">待升級</v-chip>
        <v-chip color="deep-orange accent-2" v-show="setting.updateFirmwareStarted===2">升級中</v-chip>
        <v-chip color="red accent-2" v-show="setting.resetStarted">待重置</v-chip>
      </v-chip-group>
    </v-row>
    <v-divider/>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5">全選</v-col>
          <div class="col ps-0 ms-0">
            <v-btn icon style="height:24px;width:24px" v-show="allSelected" @click="selectedAll(!allSelected)">
              <v-icon>check_box</v-icon>
            </v-btn>
            <v-btn icon style="height:24px;width:24px" v-show="!allSelected" @click="selectedAll(!allSelected)">
              <v-icon>check_box_outline_blank</v-icon>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000000001) === 0b0000000000000000000000000000001">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5">IP1</v-col>
          <v-text-field class="col"
                        v-model="setting.ip1"
                        :prepend-icon="ip1Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="ip1Selected=!ip1Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000000010) === 0b0000000000000000000000000000010">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5">IP2</v-col>
          <v-text-field class="col"
                        v-model="setting.ip2"
                        :prepend-icon="ip2Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="ip2Selected=!ip2Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000000100) === 0b0000000000000000000000000000100">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">PORT</v-col>
          <v-text-field class="col"
                        v-model="setting.port"
                        :prepend-icon="portSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="portSelected=!portSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-show="(vehicleDetectorSetting & 0b0000000000000000000000000001000) === 0b0000000000000000000000000001000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁X0</v-col>
          <v-text-field class="col"
                        v-model="setting.x0"
                        :prepend-icon="x0Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="x0Selected=!x0Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000010000) === 0b0000000000000000000000000010000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁Y0</v-col>
          <v-text-field class="col"
                        v-model="setting.y0"
                        :prepend-icon="y0Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="y0Selected=!y0Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000100000) === 0b0000000000000000000000000100000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁Z0</v-col>
          <v-text-field class="col"
                        v-model="setting.z0"
                        :prepend-icon="z0Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="z0Selected=!z0Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000000111000) === 0b0000000000000000000000000111000">
        <v-row>
          <v-col class="text-end">
            <v-btn color="primary" @click="showDeviceStatus">選擇數據</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000001000000) === 0b0000000000000000000000001000000 && userRole === 'ROOT'">
        <v-row>
          <div class="text-end text-lg-h5" style="width: 35%">地磁概率表
            <v-btn icon style="height:24px;width:24px" v-show="magTableSelected"
                   @click="magTableSelected=!magTableSelected">
              <v-icon>check_box</v-icon>
            </v-btn>
            <v-btn icon style="height:24px;width:24px" v-show="!magTableSelected"
                   @click="magTableSelected=!magTableSelected">
              <v-icon>check_box_outline_blank</v-icon>
            </v-btn>
          </div>
          <div class="ps-0 pe-0" style="width: 65%" v-if="setting.magTable">
            <div class="input-group" v-for="i in setting.magTable.length/2" v-bind:key="i">
              <v-text-field style="max-width: 50%"
                            v-for="n in 2"
                            v-model="setting.magTable[((i-1)*2)+(n-1)]" v-bind:key="n"
                            outlined/>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col class="text-end ps-0 pe-0">
            <v-btn-toggle>
              <v-btn color="primary" @click="resetMagTable">預設地磁概率表</v-btn>
              <v-btn color="primary" @click="resetMagTable2">預設地磁概率表2</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000010000000) === 0b0000000000000000000000010000000 && userRole === 'ROOT'">
        <v-row>
          <div class="text-end text-lg-h5 pt-3" style="width: 35%">紅外概率表
            <v-btn icon style="height:24px;width:24px" v-show="irTableSelected"
                   @click="irTableSelected=!irTableSelected">
              <v-icon>check_box</v-icon>
            </v-btn>
            <v-btn icon style="height:24px;width:24px" v-show="!irTableSelected"
                   @click="irTableSelected=!irTableSelected">
              <v-icon>check_box_outline_blank</v-icon>
            </v-btn>
          </div>
          <div class="ps-0 pe-0" style="width: 65%" v-if="setting.irTable">
            <div class="input-group" v-for="i in setting.irTable.length/2" v-bind:key="i">
              <v-text-field style="max-width: 50%"
                            v-for="n in 2"
                            v-model="setting.irTable[((i-1)*2)+(n-1)]" v-bind:key="n"
                            outlined/>
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col class="text-end ps-0 pe-0">
            <v-btn-toggle>
              <v-btn color="primary" @click="resetIrTable">預設紅外概率表</v-btn>
              <v-btn color="primary" @click="resetIrTable2">預設紅外概率表2</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000000100000000) === 0b0000000000000000000000100000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁變化閥值</v-col>
          <v-text-field class="col"
                        v-model="setting.changeThresholdMag"
                        :prepend-icon="changeThresholdMagSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="changeThresholdMagSelected=!changeThresholdMagSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000001000000000) === 0b0000000000000000000001000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">紅外變化閥值</v-col>
          <v-text-field class="col"
                        v-model="setting.changeThresholdIR"
                        :prepend-icon="changeThresholdIRSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="changeThresholdIRSelected=!changeThresholdIRSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000001000000000000) === 0b0000000000000000001000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁觸發閥值</v-col>
          <v-text-field class="col"
                        v-model="setting.triggerThresholdMag"
                        :prepend-icon="triggerThresholdMagSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="triggerThresholdMagSelected=!triggerThresholdMagSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000010000000000000) === 0b0000000000000000010000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁穩定閥值</v-col>
          <v-text-field class="col" v-model="setting.stableThresholdMag"
                        :prepend-icon="stableThresholdMagSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="stableThresholdMagSelected=!stableThresholdMagSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000100000000000000) === 0b0000000000000000100000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">地磁穩定時間</v-col>
          <v-text-field class="col"
                        v-model="setting.stableMagTime"
                        :prepend-icon="stableMagTimeSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="stableMagTimeSelected=!stableMagTimeSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000100000000000) === 0b0000000000000000000100000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">心跳包間隔(分)</v-col>
          <v-text-field class="col"
                        v-model="setting.heartbeatIntervals"
                        :prepend-icon="heartbeatIntervalsSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="heartbeatIntervalsSelected=!heartbeatIntervalsSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000000000010000000000) === 0b0000000000000000000010000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">四維向量閥值</v-col>
          <v-text-field class="col"
                        v-model="setting.changeThresholdFDV"
                        :prepend-icon="changeThresholdFDVSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="changeThresholdFDVSelected=!changeThresholdFDVSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000001000000000000000000) === 0b0000000000001000000000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">IP3心跳包間隔(時)</v-col>
          <v-text-field class="col" v-model="setting.ip3Intervals"
                        :prepend-icon="ip3IntervalsSelected?'check_box':'check_box_outline_blank'"
                        @click:prepend="ip3IntervalsSelected=!ip3IntervalsSelected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000010000000000000000) === 0b0000000000000010000000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">T3412</v-col>
          <v-text-field class="col"
                        v-model="setting.t3412"
                        :prepend-icon="t3412Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="t3412Selected=!t3412Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000000100000000000000000) === 0b0000000000000100000000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">T3324</v-col>
          <v-text-field class="col"
                        v-model="setting.t3324"
                        :prepend-icon="t3324Selected?'check_box':'check_box_outline_blank'"
                        @click:prepend="t3324Selected=!t3324Selected"
                        outlined/>
        </v-row>
      </v-col>
      <v-col cols="12" md="6"
             v-if="(vehicleDetectorSetting & 0b0000000000010000000000000000000) === 0b0000000000010000000000000000000">
        <v-row>
          <v-col cols="3" class="text-end text-lg-h5 pt-3">IMEI ＆ 多IP選項</v-col>
          <v-select class="col" v-model="setting.lastOption"
                    :items="[
                    {text:'IMSI ＆ 單IP發送',value:0},
                    {text:'IMEI ＆ 單IP發送',value:1},
                    {text:'IMSI ＆ 多IP發送',value:2},
                    {text:'IMEI ＆ 多IP發送',value:3}
                ]"
                    :prepend-icon="lastOptionSelected?'check_box':'check_box_outline_blank'"
                    @click:prepend="lastOptionSelected=!lastOptionSelected"
                    outlined/>
        </v-row>
      </v-col>
    </v-row>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" @click="save">儲存</v-btn>
    </v-card-actions>
    <v-dialog content-class="w-75"
              scrollable
              :value="selectDeviceStatus"
              @click:outside="selectDeviceStatus = undefined">
      <v-card v-if="!!selectDeviceStatus">
        <v-card-title>
          <p>選擇封包參數</p>
          <v-spacer/>
          <v-btn icon
                 @click="selectDeviceStatus = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-client-table :columns="columns" :data="data" :options="options"
                          @pagination="onLoaded($event)">
            <span slot="changeTime" slot-scope="{row}">
              {{ $moment(row.changeTime).format('YYYY-MM-DD HH:mm:ss') }}
            </span>
            <span slot="uploadTime" slot-scope="{row}">
              {{ $moment(row.uploadTime).format('YYYY-MM-DD HH:mm:ss') }}
            </span>
            <span slot="action" slot-scope="{row}">
              <v-btn color="primary"
                     @click="setting.x0=row.x;setting.y0=row.y;setting.z0=row.z;selectDeviceStatus=undefined">選擇</v-btn>
            </span>
          </v-client-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {getDeviceSetting, patchDeviceSetting, getDeviceNet, getStatusLogs} from "@/Api";
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore} from "@/Store";

export default {
  name: "SettingFrom",
  props: {
    deviceId: Array,
  },
  computed: {
    ...mapState(mainStore, ['userId', 'userName', 'userRole', 'vehicleDetectorSetting']),
  },
  data() {
    return {
      title: this.deviceId.join(',').substring(0, 20),
      setting: {},
      imsi: undefined,
      allSelected: true,
      ip1Selected: true,
      ip2Selected: true,
      portSelected: true,
      x0Selected: true,
      y0Selected: true,
      z0Selected: true,
      magTableSelected: true,
      irTableSelected: true,
      changeThresholdMagSelected: true,
      changeThresholdIRSelected: true,
      triggerThresholdMagSelected: true,
      stableThresholdMagSelected: true,
      stableMagTimeSelected: true,
      heartbeatIntervalsSelected: true,
      changeThresholdFDVSelected: true,
      t3412Selected: true,
      t3324Selected: true,
      ip3IntervalsSelected: true,
      lastOptionSelected: true,
      selectDeviceStatus: false,
      columns: ['uploadReason', 'x', 'y', 'z', 'm', 'd', 'cp', 'changeTime', 'uploadTime', 'action'],
      data: [],
      options: {
        headings: {
          uploadReason: '上傳原因',
          m: '地磁繞擾動',
          d: '紅外值',
          cp: '有車概率(%)',
          changeTime: '車位變化時間',
          uploadTime: '上傳時間',
          action: '操作'
        },
        orderBy: {ascending: false, column: 'uploadTime'},
        sortable: ['uploadReason', 'x', 'y', 'z', 'm', 'd', 'cp', 'changeTime', 'uploadTime'],
        filterable: false,
      }
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    selectedAll(value) {
      this.allSelected = value
      this.ip1Selected = value
      this.ip2Selected = value
      this.portSelected = value
      this.x0Selected = value
      this.y0Selected = value
      this.z0Selected = value
      this.magTableSelected = value
      this.irTableSelected = value
      this.changeThresholdMagSelected = value
      this.changeThresholdIRSelected = value
      this.triggerThresholdMagSelected = value
      this.stableThresholdMagSelected = value
      this.stableMagTimeSelected = value
      this.heartbeatIntervalsSelected = value
      this.changeThresholdFDVSelected = value
      this.ip3IntervalsSelected = value
      this.lastOptionSelected = value
      this.t3412Selected = value
      this.t3324Selected = value
    },
    resetMagTable() {
      this.setting.magTable = JSON.parse(atob('WzAsMSwxMCwxLDQwLDEwLDEyMCw4MywxNTAsOTgsMjAwLDk5XQ'))
    },
    resetIrTable() {
      this.setting.irTable = JSON.parse(atob('WzAsOTksMTAsOTksNjAsOTAsODAsMTAsOTksMSwxMDAsMV0'))
    },
    resetMagTable2() {
      this.setting.magTable = JSON.parse(atob('WzI2LDAsMzcsMzMsNDMsNDMsNjAsNjIsNzgsODQsMTQ5LDk5XQ'))
    },
    resetIrTable2() {
      this.setting.irTable = JSON.parse(atob('WzAsOTksMTAsOTksNjAsOTAsODAsNjAsOTksNTAsMTA5LDFd'))
    },
    async showDeviceStatus() {
      this.selectDeviceStatus = true
      this.showLoading()
      if (this.deviceId.length > 0) {
        let list = await getStatusLogs(this.deviceId[0], null, null, 0)
        if (list.length > 0) {
          this.data = list
        }
      }
      this.dismissLoading()
    },
    async onLoaded(event) {
      if (event === parseInt(this.data.length / 10)) {
        let page = parseInt(this.data.length / 100) + (this.data.length % 100 > 0 ? 1 : 0)
        this.showLoading()
        let list = await getStatusLogs(this.deviceId[0], null, null, page)
        if (list.length > 0) {
          this.data = [...this.data, ...list]
        }
        this.dismissLoading()
      }
    },
    async save() {
      this.showLoading()
      let params = {}
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000000001) === 0b0000000000000000000000000000001 && this.ip1Selected) params.ip1 = this.setting.ip1
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000000010) === 0b0000000000000000000000000000010 && this.ip2Selected) params.ip2 = this.setting.ip2
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000000100) === 0b0000000000000000000000000000100 && this.portSelected) params.port = this.setting.port
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000001000) === 0b0000000000000000000000000001000 && this.x0Selected) params.x0 = this.setting.x0
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000010000) === 0b0000000000000000000000000010000 && this.y0Selected) params.y0 = this.setting.y0
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000000100000) === 0b0000000000000000000000000100000 && this.z0Selected) params.z0 = this.setting.z0
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000001000000) === 0b0000000000000000000000001000000 && this.magTableSelected && this.setting.magTable) params.magTable = this.setting.magTable.join(',')
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000010000000) === 0b0000000000000000000000010000000 && this.irTableSelected && this.setting.irTable) params.irTable = this.setting.irTable.join(',')
      if ((this.vehicleDetectorSetting & 0b0000000000000000000000100000000) === 0b0000000000000000000000100000000 && this.changeThresholdMagSelected) params.changeThresholdMag = this.setting.changeThresholdMag
      if ((this.vehicleDetectorSetting & 0b0000000000000000000001000000000) === 0b0000000000000000000001000000000 && this.changeThresholdIRSelected) params.changeThresholdIR = this.setting.changeThresholdIR
      if ((this.vehicleDetectorSetting & 0b0000000000000000000010000000000) === 0b0000000000000000000010000000000 && this.changeThresholdFDVSelected) params.changeThresholdFDV = this.setting.changeThresholdFDV
      if ((this.vehicleDetectorSetting & 0b0000000000000000000100000000000) === 0b0000000000000000000100000000000 && this.heartbeatIntervalsSelected) params.heartbeatIntervals = this.setting.heartbeatIntervals
      if ((this.vehicleDetectorSetting & 0b0000000000000000001000000000000) === 0b0000000000000000001000000000000 && this.triggerThresholdMagSelected) params.triggerThresholdMag = this.setting.triggerThresholdMag
      if ((this.vehicleDetectorSetting & 0b0000000000000000010000000000000) === 0b0000000000000000010000000000000 && this.stableThresholdMagSelected) params.stableThresholdMag = this.setting.stableThresholdMag
      if ((this.vehicleDetectorSetting & 0b0000000000000000100000000000000) === 0b0000000000000000100000000000000 && this.stableMagTimeSelected) params.stableMagTime = this.setting.stableMagTime
      if ((this.vehicleDetectorSetting & 0b0000000000000010000000000000000) === 0b0000000000000010000000000000000 && this.t3412Selected) params.t3412 = this.setting.t3412
      if ((this.vehicleDetectorSetting & 0b0000000000000100000000000000000) === 0b0000000000000100000000000000000 && this.t3324Selected) params.t3324 = this.setting.t3324
      if ((this.vehicleDetectorSetting & 0b0000000000001000000000000000000) === 0b0000000000001000000000000000000 && this.ip3IntervalsSelected) params.ip3Intervals = this.setting.ip3Intervals
      if ((this.vehicleDetectorSetting & 0b0000000000010000000000000000000) === 0b0000000000010000000000000000000 && this.lastOptionSelected) params.lastOption = this.setting.lastOption
      const response = await patchDeviceSetting(this.deviceId, params)
      if (response) {
        this.success('更新成功')
      } else {
        this.error('更新失敗')
      }
      this.dismissLoading()
      this.$emit('close');
    }
  },
  async mounted() {
    if (this.deviceId.length > 0) {
      this.showLoading()
      let data = await getDeviceSetting(this.deviceId[0])
      this.setting = {
        ...data,
        magTable: data.magTable?.split(',') ?? [],
        irTable: data.irTable?.split(',') ?? []
      }
      if (this.deviceId.length === 1) {
        let net = await getDeviceNet(this.deviceId[0])
        this.imsi = net.imsi
      }
      this.dismissLoading()
    }
  }
}
</script>

<style scoped>
</style>