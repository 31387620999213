import {getToke, goLoginPage} from "@/Api";

/**
 * @param deviceId 停車柱ID，模糊搜尋用
 * @param enabled 是否啟用
 * @param page 頁數
 * */
export const getParkingPoles = async ({
                                          deviceId,
                                          tags,
                                          excludeTags,
                                          enabled,
                                          unbinding,
                                          organizationAppId,
                                          districtAppId,
                                          page = 0,
                                      }) => {
    const token = await getToke()
    let url = '/api/oauth/parking-pole?'
    if (deviceId) {
        if (deviceId.includes(',')) {
            url += `parkingPoleId=${deviceId}&`
        } else {
            url += `idLike=${deviceId}&`
        }
    }
    if (tags) {
        if (Array.isArray(tags)) {
            url += `tags=`
            tags.forEach(it => {
                url += `${it},`
            })
            url = url.substring(0, url.length - 2)
            url += `&`
        } else {
            url += `tags=${tags}&`
        }
    }
    if (excludeTags) {
        if (Array.isArray(excludeTags)) {
            url += `excludeTags=`
            excludeTags.forEach(it => {
                url += `${it},`
            })
            url = url.substring(0, url.length - 2)
            url += `&`
        } else {
            url += `excludeTags=${excludeTags}&`
        }
    }
    if (!(enabled === undefined || enabled === null)) url += `enabled=${enabled}&`
    if (!(unbinding === undefined || unbinding === null)) url += `unbinding=${unbinding}&`
    if (organizationAppId) url += `organizationAppId=${organizationAppId}&`
    if (districtAppId) url += `districtAppId=${districtAppId}&`
    url += `page=${page}&`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * @param deviceId 停車柱ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getParkingPoleStatusLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    let url = `/api/oauth/parking-pole/status/list/${deviceId}?`
    if (startTime) url += `startTime=${startTime}&`
    if (endTime) url += `endTime=${endTime}&`
    if (page) url += `page=${page}`
    const data = await fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * @param deviceId 設備ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getParkingPoleChangeLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/parking-pole/changeLog/list/${deviceId}?page=${page}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * @param deviceId 停車柱ID
 * @param startTime 搜尋開始時間
 * @param endTime 搜尋結束時間
 * @param page 頁數
 * */
export const getParkingPoleUnmoralLogs = async (deviceId, startTime, endTime, page) => {
    const token = await getToke()
    const data = await fetch(`/api/oauth/parking-pole/unmoralLog/list/${deviceId}?page=${page}&startTime=${startTime}&endTime=${endTime}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
        return []
    }
    return []
}

/**
 * @param deviceId 停車柱ID
 * */
export const getParkingPoleSetting = async (deviceId) => {
    const token = await getToke()
    const data = await fetch(`/api/manage/parking-pole/setting/${deviceId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return undefined
}

/**
 * @param deviceId 停車柱ID
 * @param params 設定內容
 * */
export const patchParkingPoleSetting = async (deviceId, params) => {
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => {
            body.append('deviceId', id)
        })
    } else {
        body.append('deviceId', deviceId)
    }
    for (let key in params) {
        body.append(key, params[key])
    }
    const token = await getToke()
    const data = await fetch(`/api/manage/parking-pole/setting`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data
}

/**
 * 取得停車柱上傳圖片
 */
export const getParkingPoleUploadImageLocalUrl = async (imageId) => {
    const token = await getToke()
    const response = await fetch(`/api/oauth/parking-pole/image/${imageId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (response.ok) {
        const blob = await response.blob()
        return URL.createObjectURL(blob)
    } else if (response.status === 401) {
        await goLoginPage()
    } else {
        console.log('error on handle data: ', response)
        return ''
    }
}

/**
 * 查詢標籤
 */
export const getParkingPoleAllTags = async () => {
    const token = await getToke()
    const data = await fetch(`/api/manage/parking-pole/tag`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    if (data.ok) {
        return await data.json()
    } else if (data.status === 401) {
        await goLoginPage()
    }
    return []
}

/**
 * 更新標籤
 */
export const updateTags = async (deviceId, add, remove) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(s => body.append('deviceId', s))
    } else if (deviceId) {
        body.append('deviceId', deviceId)
    }
    if (Array.isArray(add)) {
        add.forEach(s => body.append('add', s))
    } else if (add) {
        body.append('add', add)
    }
    if (Array.isArray(remove)) {
        remove.forEach(s => body.append('remove', s))
    } else if (remove) {
        body.append('remove', add)
    }
    const data = await fetch(`/api/manage/parking-pole/tag`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return data.ok
}


/**
 * 停車柱設備綁定組織
 *
 * @param deviceId 停車柱ID
 * @param organizationAppId 組織AppId（tag用）
 * */
export const deviceBindOrganization = async (deviceId, organizationAppId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    body.append('organizationAppId', organizationAppId)
    const data = await fetch('/api/manage/parking-pole/bind/organization', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}


/**
 * 停車柱設備解綁定組織
 *
 * @param deviceId 設備ID
 * */
export const deviceUnbindOrganization = async (deviceId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    const data = await fetch(`/api/manage/parking-pole/unbind/organization`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 停車柱設備綁定場域
 *
 * @param deviceId 設備ID
 * @param fieldId 場域ID
 * */
export const deviceBindField = async (deviceId, fieldId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    body.append('fieldId', fieldId)
    const data = await fetch(`/api/manage/parking-pole/bind/field`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}

/**
 * 停車柱設備解除綁定場域
 *
 * @param deviceId 設備ID
 * @param fieldId 場域ID
 * */
export const deviceUnbindField = async (deviceId) => {
    const token = await getToke()
    const body = new URLSearchParams()
    if (Array.isArray(deviceId)) {
        deviceId.forEach(id => body.append('deviceId', id))
    } else {
        body.append('deviceId', deviceId)
    }
    const data = await fetch(`/api/manage/parking-pole/unbind/field`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: body
    })
    if (data.status === 401) {
        await goLoginPage()
    }
    return {
        ok: data.ok,
        message: await data.text()
    }
}
