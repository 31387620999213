<template>
  <div class="h-100">
    <v-app-bar color="main" app clipped-left dark>
      <v-app-bar-title>正旻科技停車管理資訊平台</v-app-bar-title>
      <v-spacer/>
      <v-btn plain @click="toggleDrawer">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="h-100">
      <v-navigation-drawer
          class="h-100"
          app clipped dark
          v-model="showDrawer"
          :mini-variant.sync="miniDrawer">
        <v-list>
          <v-list-item v-if="organizationEnable">
            <v-list-item-icon>
              <v-icon>apartment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>公司</v-list-item-title>
              <v-select
                  class="mt-3"
                  v-show="!miniDrawer"
                  outlined
                  dense
                  item-text="name"
                  item-value="appId"
                  :disabled="isDeviceLoading"
                  :value="selectOrganizationAppId"
                  :items="organizations"
                  @change="onOrganizationChange($event)"/>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="organizationEnable">
            <v-list-item-icon>
              <v-icon>flag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>場域</v-list-item-title>
              <v-select
                  class="mt-3"
                  v-show="!miniDrawer"
                  outlined
                  dense
                  item-text="name"
                  item-value="appId"
                  :disabled="isDeviceLoading"
                  :value="selectDistrictAppId"
                  :items="districts"
                  @change="onFieldChange($event)"
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-group prepend-icon="person" value="true" color="white" no-action>
            <template v-slot:activator>
              <v-list-item-title>個人</v-list-item-title>
            </template>
            <v-list-item @click="updatePasswordIsShow=true">
              <v-list-item-icon>
                <v-icon>password</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>修改密碼</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group prepend-icon="settings" value="true" color="white" no-action
                        v-if="oauthEnable || organizationEnable"
                        v-show="['ROOT'].includes(userRole)">
            <template v-slot:activator>
              <v-list-item-title>系統管理</v-list-item-title>
            </template>
            <v-list-item v-if="oauthEnable"
                         v-show="(firstPermissions & 0b000000000000000000000000000001) === 0b000000000000000000000000000001"
                         :to="to('/user')">
              <v-list-item-icon>
                <v-icon>group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>使用者</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="organizationEnable"
                         v-show="(firstPermissions & 0b000000000000000000010000000000) === 0b000000000000000000010000000000"
                         :to="to('/organization')">
              <v-list-item-icon>
                <v-icon>corporate_fare</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>組織</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group prepend-icon="blur_circular" value="true" color="white" no-action
                        v-if="vehicleDetectorEnable"
                        v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
            <template v-slot:activator>
              <v-list-item-title>地磁管理</v-list-item-title>
            </template>
            <v-list-item v-if="vehicleDetectorEnable"
                         v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole)"
                         :to="to('/device')">
              <v-list-item-icon>
                <v-icon>search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>地磁設備查詢</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group prepend-icon="ev_station" value="true" color="white" no-action
                        v-if="parkingPoleLuciferEnable"
                        v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole)">
            <template v-slot:activator>
              <v-list-item-title>停車柱管理</v-list-item-title>
            </template>
            <v-list-item v-if="parkingPoleLuciferEnable"
                         v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole)"
                         :to="to('/parking-pole')">
              <v-list-item-icon>
                <v-icon>search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>停車柱設備查詢</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group prepend-icon="directions_car" value="true" color="white" no-action
                        v-if="positionEnable">
            <template v-slot:activator>
              <v-list-item-title>車位管理</v-list-item-title>
            </template>
            <v-list-item v-if="positionEnable"
                         v-show="['ROOT', 'MANAGER', 'STAFF', 'MEMBER'].includes(userRole) && (firstPermissions & 0b000000000000000100000000000000) === 0b000000000000000100000000000000"
                         :to="to('/dashboard')">
              <v-list-item-icon>
                <v-icon>query_stats</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>儀表板</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="positionEnable"
                         :to="to('/position/map')">
              <v-list-item-icon>
                <v-icon>map</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>地圖</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="positionEnable"
                         :to="to('/position/search')">
              <v-list-item-icon>
                <v-icon>garage</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>車位列表</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="positionEnable"
                         v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole)"
                         :to="to('/position/keyword')">
              <v-list-item-icon>
                <v-icon>abc</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>關鍵字查詢</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="positionEnable"
                         v-show="['ROOT','MANAGER','STAFF'].includes(userRole) && (firstPermissions & 0b000000000000000010000000000000) === 0b000000000000000010000000000000"
                         :to="to('/position/editmap')">
              <v-list-item-icon>
                <v-icon>edit_location_alt</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>車位編輯地圖</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group prepend-icon="ev_station" value="true" color="white" no-action hidden>
            <template v-slot:activator>
              <v-list-item-title>充電樁</v-list-item-title>
            </template>
          </v-list-group>
          <v-list-group prepend-icon="traffic" value="true" color="white" no-action hidden>
            <template v-slot:activator>
              <v-list-item-title>燈牌</v-list-item-title>
            </template>
          </v-list-group>
          <v-list-group prepend-icon="info" value="true" color="white" no-action
                        v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole) && (firstPermissions & 0b000000000000001000000000000000) === 0b000000000000001000000000000000"
          >
            <template v-slot:activator>
              <v-list-item-title>紀錄</v-list-item-title>
            </template>
            <v-list-item
                v-show="['ROOT', 'MANAGER','STAFF'].includes(userRole) && (firstPermissions & 0b000000000000001000000000000000) === 0b000000000000001000000000000000"
                :to="to('/log')">
              <v-list-item-icon>
                <v-icon>manage_search</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>異動紀錄查詢</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <template v-slot:append>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>LOGOUT</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-navigation-drawer>
      <div class="h-100 p-2">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer app/>
    <Loading :active.sync="loading"
             color="#0d6efd"
             background-color="#000000"
             is-full-page/>
    <Loading :active.sync="showLoading"
             color="#0d6efd"
             background-color="#000000"
             is-full-page/>
    <v-snackbar :light="color === 'white'" v-model="toastIsShow" :color="color">{{ message }}</v-snackbar>
    <v-dialog
        content-class="w-50"
        :value="updatePasswordIsShow"
        @click:outside="updatePasswordIsShow=undefined">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-btn icon
                 @click="updatePasswordIsShow=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UpdatePasswordFrom @close="updatePasswordIsShow=undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import {useLoadingStore, mainStore, toast} from "@/Store";
import {mapActions, mapState} from "pinia";
import {goLoginPage} from "@/Api";
import UpdatePasswordFrom from "@/components/UpdatePasswordFrom";

export default {
  name: 'Exoskeleton',
  components: {
    UpdatePasswordFrom,
    Loading
  },
  computed: {
    ...mapState(toast, ['message', 'color', 'show']),
    ...mapState(useLoadingStore, {loading: 'visibility'}),
    ...mapState(mainStore, {isDeviceLoading: 'loading'}),
    ...mapState(mainStore, ['oauthEnable', 'organizationEnable', 'positionEnable', 'vehicleDetectorEnable', 'parkingPoleLuciferEnable']),
    ...mapState(mainStore, ['userId', 'userName', 'userRole', 'firstPermissions']),
    ...mapState(mainStore, ['organizations', 'districts', 'devices', 'selectOrganizationAppId', 'selectDistrictAppId', 'showLoading'])
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) this.toastIsShow = value
      },
      immediate: true
    },
    toastIsShow: {
      handler: function (value) {
        if (!value) this.dismiss()
      },
      immediate: true
    }
  },
  data() {
    return {
      showDrawer: false,
      miniDrawer: true,
      toastIsShow: false,
      updatePasswordIsShow: false
    }
  },
  methods: {
    ...mapActions(toast, ['dismiss']),
    ...mapActions(mainStore, ['init', 'onOrganizationChange', 'onFieldChange']),
    toggleDrawer() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.showDrawer = true
        this.miniDrawer = !this.miniDrawer
      } else {
        this.showDrawer = !this.showDrawer
        this.miniDrawer = false
      }
    },
    logout() {
      goLoginPage().then()
    },
    to(path) {
      return {path: path, query: {...this.$route.query}}
    }
  },
  mounted() {
    this.init().then()
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.showDrawer = true
      this.miniDrawer = true
    } else {
      this.showDrawer = false
      this.miniDrawer = false
    }
  }
}
</script>

<style>
</style>