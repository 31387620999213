<template>
  <div>
    <v-row>
      <v-col col="12" sm="9">
        <div class="input-group">
          <date-picker
              v-model="dateRange[0]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
          <span class="input-group-text bg-transparent border-0">~</span>
          <date-picker
              v-model="dateRange[1]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
        </div>
      </v-col>
      <v-col class="text-end" col="12" sm="1">
        <v-btn color="primary" @click="send" :disabled="loading">搜尋</v-btn>
      </v-col>
      <v-col class="text-start" col="12" sm="2">
        <v-btn color="primary" @click="exportData" :disabled="loading" v-show="data.length > 0">匯出xlsx</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="data" :options="options" @pagination="onLoaded($event)">
      <span slot="code" slot-scope="{row}">
        {{getCodeString(row.code)}}
      </span>
      <span slot="time" slot-scope="{row}">
        {{ $moment(row.time).format('YYYY-MM-DD HH:mm:ss') }}
      </span>
      <span slot="isOccupy" slot-scope="{row}">
          {{ row.isOccupy ? '是' : '否' }}
        </span>
    </v-client-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {useLoadingStore} from "@/Store";
import {utils, writeFile} from "xlsx";
import {getParkingPoleUnmoralLogs} from "@/Api-parking-pole";

export default {
  name: "ParkingPoleUnmoralLogFrom",
  props: {
    deviceId: String
  },
  computed: {
    ...mapState(useLoadingStore, {loading: 'visibility'}),
  },
  data() {
    return {
      dateRange: [this.$moment().subtract(7, 'days').toDate(), this.$moment().toDate()],
      startTime: null,
      endTime: null,
      columns: ['code', 'time'],
      data: [],
      options: {
        headings: {
          code: '錯誤代號',
          time: '發生時間'
        },
        orderBy: {ascending: false, column: 'time'},
        sortable: ['code', 'time'],
        filterable: false
      }
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async send() {
      this.startTime = this.$moment(this.dateRange[0]).set("hour", 0).set("minute", 0).set('second', 0).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.endTime = this.$moment(this.dateRange[1]).set("hour", 23).set("minute", 59).set('second', 59).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.showLoading()
      this.data = await getParkingPoleUnmoralLogs(this.deviceId, this.startTime, this.endTime, 0)
      this.dismissLoading()
    },
    async onLoaded(event) {
      if (event === parseInt(this.data.length / 10)) {
        let page = parseInt(this.data.length / 100) + (this.data.length % 100 > 0 ? 1 : 0)
        this.showLoading()
        let list = await getParkingPoleUnmoralLogs(this.deviceId, this.startTime, this.endTime, page)
        if (list.length > 0) {
          this.data = [...this.data, ...list]
        }
        this.dismissLoading()
      }
    },
    getCodeString(value) {
      switch (value) {
        case 0 :
          return '無異常'
        case 1 :
          return '掉電復位'
        case 2 :
          return '看門狗復位'
        case 3 :
          return '韌體異常復位'
        case 4 :
          return '韌體異常復位'
        case 5 :
          return '看門狗復位2'
        case 6 :
          return 'BOR復位'
        case 7 :
          return '未知復位'
        case 20 :
          return '通訊模組無回應'
        case 21 :
          return '無SIM卡'
        case 22 :
          return '註冊SIM卡被拒絕'
        case 23 :
          return '註冊SIM卡超時'
        case 24 :
          return '網路註冊失敗'
        case 25 :
          return '註冊網路被拒絕'
        case 26 :
          return '註冊網路超時'
        case 27 :
          return '連接TCP失敗'
        case 28 :
          return '連接TCP超時'
        case 29 :
          return '服務器無回應'
        case 30 :
          return '未知異常'
        case 40 :
          return '進入PSM失敗'
        case 103 :
          return '數據丟失'
        default:
          return `其他(${value})`
      }
    },
    exportData() {
      const row = []
      this.data.forEach(item => {
        row.push({
          '錯誤代號': this.getCodeString(item.code),
          '發生時間': this.$moment(item.time).format('YYYY-MM-DD HH:mm:ss')
        })
      })
      const ws = utils.json_to_sheet(row);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");
      writeFile(wb, `設備${this.deviceId}異常紀錄.xlsx`, {bookType: "xlsx"});
    }
  }
}
</script>

<style scoped>

</style>