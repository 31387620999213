<template>
  <div>
    <v-row>
      <v-col col="12" sm="9">
        <div class="input-group">
          <date-picker
              v-model="dateRange[0]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
          <span class="input-group-text bg-transparent border-0">~</span>
          <date-picker
              v-model="dateRange[1]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
        </div>
      </v-col>
      <v-col class="text-end" col="12" sm="1">
        <v-btn color="primary" @click="send" :disabled="loading">搜尋</v-btn>
      </v-col>
      <v-col class="text-start" col="12" sm="2">
        <v-btn color="primary" @click="exportData" :disabled="loading" v-show="data.length > 0">匯出xlsx</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="data" :options="options"
                    @pagination="onLoaded($event)">
      <span slot="uploadReason" slot-scope="{row}">
        <span v-if="row.uploadReason === 1">心跳包</span>
        <span v-else-if="row.uploadReason === 2">車位狀態變化</span>
        <span v-else-if="row.uploadReason === 3">重新上傳</span>
        <span v-else-if="row.uploadReason === 4">設備重啟</span>
        <span v-else-if="row.uploadReason === 5">本地初始化</span>
        <span v-else>{{ row.uploadReason }}</span>
      </span>
      <span slot="changeTime" slot-scope="{row}">
        {{ $moment(row.changeTime).format('YYYY-MM-DD HH:mm:ss') }}
      </span>
      <span slot="uploadTime" slot-scope="{row}">
        {{ $moment(row.uploadTime).format('YYYY-MM-DD HH:mm:ss') }}
        </span>
      <span slot="licNo" slot-scope="{row}">
        <span v-if="row.licNo">{{ row.licNo }}</span>
        <span v-else></span>
      </span>
      <span slot="photoName" slot-scope="{row}">
        <v-btn v-if="row.licNo" color="primary" @click="showPhoto(row.photoName)">查看圖片</v-btn>
        <span v-else></span>
      </span>
    </v-client-table>

    <v-dialog :key="fileViewerKey"
              :value="fileViewerDialog"
              max-width="700px"
              @click:outside="fileViewerDialog=false">
      <FileViewer :file-id="fileId"/>
    </v-dialog>

  </div>
</template>

<script>

import {mapActions, mapState} from "pinia";
import {useLoadingStore} from "@/Store";
import {utils, writeFile} from "xlsx";
import {getParkingPoleStatusLogs} from "@/Api-parking-pole";
import FileViewer from "@/components/FileViewer.vue";

export default {
  name: "ParkingPoleStatusFrom",
  components: {FileViewer},
  props: {
    deviceId: String
  },
  computed: {
    ...mapState(useLoadingStore, {loading: 'visibility'}),
  },
  data() {
    return {
      dateRange: [this.$moment().subtract(7, 'days').toDate(), this.$moment().toDate()],
      columns: ['pushCount', 'irCount', 'changeCount', 'batteryVoltage', 'capacitorVoltage', 'q', 'rsrp', 'sinr', 'uploadReason', 'licNo', 'photoName', 'x', 'y', 'z', 'm', 'd', 'cp', 'netTime', 'responseTime', 'psmTime', 'changeTime', 'uploadTime'],
      data: [],
      options: {
        headings: {
          pushCount: '發送計數',
          irCount: '紅外計數',
          changeCount: '車位變化計數',
          batteryVoltage: '電池電壓(mV)',
          capacitorVoltage: '電容電壓(mV)',
          q: 'CSQ',
          rsrp: 'RSRP',
          sinr: 'SINR',
          uploadReason: '上傳原因',
          licNo: '車牌文字',
          photoName: '上傳圖片',
          m: '地磁繞擾動',
          d: '紅外值',
          cp: '有車概率(%)',
          netTime: '入網時間(秒)',
          responseTime: '響應時間(秒)',
          psmTime: 'PSM 時間(秒)',
          changeTime: '車位變化時間',
          uploadTime: '上傳時間'
        },
        orderBy: {ascending: false, column: 'uploadTime'},
        sortable: ['pushCount', 'irCount', 'changeCount', 'batteryVoltage', 'capacitorVoltage', 'q', 'rsrp', 'sinr', 'uploadReason', 'x', 'y', 'z', 'm', 'd', 'cp', 'netTime', 'responseTime', 'psmTime', 'changeTime', 'uploadTime'],
        filterable: false,
      },
      fileViewerDialog: false,
      fileViewerKey: 0,
      fileId: '',
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async send() {
      this.startTime = this.$moment(this.dateRange[0]).set("hour", 0).set("minute", 0).set('second', 0).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.endTime = this.$moment(this.dateRange[1]).set("hour", 23).set("minute", 59).set('second', 59).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.showLoading()
      this.data = await getParkingPoleStatusLogs(this.deviceId, this.startTime, this.endTime, 0)
      this.dismissLoading()
    },
    async onLoaded(event) {
      if (event === parseInt(this.data.length / 10)) {
        let page = parseInt(this.data.length / 100) + (this.data.length % 100 > 0 ? 1 : 0)
        this.showLoading()
        let list = await getParkingPoleStatusLogs(this.deviceId, this.startTime, this.endTime, page)
        if (list.length > 0) {
          this.data = [...this.data, ...list]
        }
        this.dismissLoading()
      }
    },
    exportData() {
      const row = []
      this.data.forEach(item => {
        row.push({
          '發送計數': item.pushCount,
          '紅外計數': item.irCount,
          '車位變化計數': item.changeCount,
          '電池電壓(mV)': item.batteryVoltage,
          '電容電壓(mV)': item.capacitorVoltage,
          'CSQ': item.q,
          'RSRP': item.rsrp,
          'SINR': item.sinr,
          '上傳原因': item.uploadReason,
          '地磁繞擾動': item.m,
          '紅外值': item.d,
          '有車概率(%)': item.cp,
          '入網時間(秒)': item.netTime,
          '響應時間(秒)': item.responseTime,
          'PSM 時間(秒)': item.psmTime,
          '車位變化時間': this.$moment(item.changeTime).format('YYYY-MM-DD HH:mm:ss'),
          '上傳時間': this.$moment(item.uploadTime).format('YYYY-MM-DD HH:mm:ss')
        })
      })
      const ws = utils.json_to_sheet(row);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");
      writeFile(wb, `設備${this.deviceId}上傳數據.xlsx`, {bookType: "xlsx"});
    },
    showPhoto(id) {
      if (!id) return
      this.fileId = id
      this.fileViewerDialog = true
      this.forceRerenderFileViewer()
    },
    forceRerenderFileViewer() {
      this.fileViewerKey += 1;
    }
  }
}
</script>

<style>
</style>