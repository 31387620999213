<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form ref="form" @submit.prevent="send">
          <v-text-field v-model="keyword"
                        placeholder="車格編號、地址、地磁ID，使用者名車"
                        append-outer-icon="send"
                        @click:append-outer="send"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="logs" :options="options">
      <span slot="createTime" slot-scope="{row}">
          {{ row.createTime ? $moment(row.createTime).format("YYYY/MM/DD HH:mm:ss") : "" }}
      </span>
    </v-client-table>
  </v-container>
</template>

<script>
import {mapActions} from "pinia";
import {useLoadingStore} from "@/Store";
import {getActionLogs} from "@/Api";

export default {
  name: "ActionLogsFrom",
  data() {
    return {
      keyword: '',
      columns: ['actionTarget', 'text', 'user', 'taskId','createTime'],
      logs: [], options: {
        headings: {
          'actionTarget': '操作對象',
          'text': '內容',
          'user': '操作者',
          'taskId':'任務ID',
          'createTime': '時間'
        },
        sortable: ['createTime'],
        filterable: false,
      },
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async send() {
      this.showLoading()
      this.logs = await getActionLogs(this.keyword)
      this.dismissLoading()
    }
  }
}
</script>

<style scoped>

</style>