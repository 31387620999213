import Vue from 'vue'
import VueRouter from 'vue-router'
import {mainStore} from "@/Store";
import ErrorPage from "@/components/ErrorPage";
import Exoskeleton from "@/components/Exoskeleton";
import DashboardPage from "@/components/DashboardPage";
import MapFrom from "@/components/MapFrom";
import PositionSearch from "@/components/PositionSearch";
import OrganizationFrom from "@/components/OrganizationFrom";
import OrganizationAddUser from "@/components/OrganizationAddUser";
import FieldFrom from "@/components/FieldFrom";
import KeywordSearchPositionFrom from "@/components/KeywordSearchPositionFrom";
import ActionLogsFrom from "@/components/ActionLogsFrom";
import EditPositionLatlngMapFrom from "@/components/EditPositionLatlngMapFrom";
import DeviceSearch from "@/components/DeviceSearch";
import QrcodeScannerFrom from "@/components/QrcodeScannerFrom";
import UserFrom from "@/components/UserFrom";
import UserEditFrom from "@/components/UserEditFrom";
import ParkingPoleSearch from "@/components/parking-pole/ParkingPoleSearch.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/scanner',
            component: QrcodeScannerFrom,
        },
        {
            path: '/',
            component: Exoskeleton,
            children: [
                {
                    path: '/',
                    component: MapFrom,
                },
                {
                    path: '/dashboard',
                    component: DashboardPage,
                },
                {
                    path: '/position/map',
                    component: MapFrom,
                },
                {
                    path: '/position/search',
                    component: PositionSearch,
                },
                {
                    path: '/position/keyword',
                    meta: {
                        securities: ['ROOT', 'MANAGER','STAFF']
                    },
                    component: KeywordSearchPositionFrom,
                },
                {
                    path: '/position/editmap',
                    meta: {
                        securities: ['ROOT', 'MANAGER','STAFF']
                    },
                    component: EditPositionLatlngMapFrom,
                },
                {
                    path: '/device',
                    meta: {
                        securities: ['ROOT', 'MANAGER','STAFF']
                    },
                    component: DeviceSearch,
                },
                {
                    path: '/parking-pole',
                    meta: {
                        securities: ['ROOT', 'MANAGER','STAFF']
                    },
                    component: ParkingPoleSearch,
                },
                {
                    path: '/log',
                    meta: {
                        securities: ['ROOT', 'MANAGER','STAFF']
                    },
                    component: ActionLogsFrom,
                },
                {
                    path: '/user',
                    meta: {
                        securities: ['ROOT']
                    },
                    component: UserFrom,
                },
                {
                    path: '/user/:username',
                    meta: {
                        securities: ['ROOT']
                    },
                    component: UserEditFrom,
                    props: true
                },
                {
                    path: '/organization',
                    meta: {
                        securities: ['ROOT']
                    },
                    component: OrganizationFrom,
                },
                {
                    path: '/organization/field/:organizationAppId',
                    meta: {
                        securities: ['ROOT']
                    },
                    component: FieldFrom,
                    props: true
                },
                {
                    path: '/organization/user/:organizationId',
                    meta: {
                        securities: ['ROOT']
                    },
                    component: OrganizationAddUser,
                    props: true
                }
            ]
        },
        {
            path: '*',
            component: ErrorPage,
            props: {errorCode: '404'}
        }
    ]
})

router.beforeEach((to, _, next) => {
    const role = mainStore().userRole
    const securities = to.meta.securities
    if (!securities) {
        next(true)
        return;
    }
    if (!role) {
        next(true)
        return;
    }
    if (!securities.includes(role)) {
        next({path: '404', query: {...to.query}})
        return
    }
    next(true)
})

export default router