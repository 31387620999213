import {defineStore} from 'pinia'
import {
    deviceBindField,
    deviceBindOrganization,
    deviceUnbindField, deviceUnbindOrganization,
    getAllField, getAllTags, getConfig,
    getPositions,
    getOrganizations, getPosition,
    me, getDevices,
} from "@/Api";
import {getParkingPoleAllTags} from "@/Api-parking-pole";
import {getParkingPoles} from "@/Api-parking-pole";

export const mainStore = defineStore('organizationAndDevice', {
    state: () => {
        return {
            oauthEnable: false,
            organizationEnable: false,
            positionEnable: false,
            vehicleDetectorEnable: false,
            parkingPoleLuciferEnable: false,
            userId: undefined,
            userName: undefined,
            userRole: undefined,
            firstPermissions: 0,
            vehicleDetectorSetting: 0,
            organizations: [{appId: '', districts: [], id: '', name: '未選擇', disabled: true}],
            districts: [{name: '無場域', id: '', appId: ''}],
            devices: [],
            selectOrganizationAppId: '',
            selectDistrictAppId: '',
            showLoading: false,
            loading: false,
        }
    },
    actions: {
        /** 初始化 */
        async init() {
            let config = await getConfig()
            this.oauthEnable = config.oauthEnable
            this.organizationEnable = config.organizationEnable
            this.positionEnable = config.positionEnable
            this.vehicleDetectorEnable = config.vehicleDetectorEnable
            this.parkingPoleLuciferEnable = config.parkingPoleLuciferEnable
            let userProfile = await me()
            if (!userProfile) return
            this.userId = userProfile.id
            this.userName = userProfile.username
            this.userRole = userProfile.role
            this.firstPermissions = userProfile.firstPermissions
            this.vehicleDetectorSetting = userProfile.vehicleDetectorSetting
            if (this.organizationEnable) {
                await this.reloadOrganizations()
            }
        },

        /**
         * 重新讀取組織清單
         * */
        async reloadOrganizations() {
            let list = await getOrganizations()
            this.organizations = [{
                appId: '',
                districts: [],
                id: '',
                name: '未選擇',
                disabled: true
            }, ...list]
            if (this.selectOrganizationAppId) {
                let list = this.organizations.find(organization => organization.appId === this.selectOrganizationAppId)?.districts ?? []
                this.districts = [{name: '無場域', id: '', appId: ''}, ...list]
                this.devices = []
            } else {
                this.districts = [{name: '無場域', id: '', appId: ''}]
            }
        },

        /**
         * 選取組織
         * */
        async onOrganizationChange(event) {
            this.selectOrganizationAppId = event
            let organization = this.organizations.find(organization => organization.appId === event)
            let list = organization?.districts ?? []
            this.districts = [{name: '無場域', id: '', appId: ''}, ...list]
            this.selectDistrictAppId = ''
            await this.reloadDevices()
        },

        /**
         * 選取場域
         * */
        async onFieldChange(event) {
            if (event) {
                this.selectDistrictAppId = this.districts.find(field => field.appId === event)?.appId ?? ''
            } else {
                this.selectDistrictAppId = ''
            }
            await this.reloadDevices()
        },

        /**
         * 重新讀取場域
         * */
        async reloadFields() {
            let list = await getAllField(this.selectOrganizationAppId)
            this.districts = [{name: '無場域', id: '', appId: ''}, ...list]
        },

        /**
         * 重新讀取設備
         * */
        async reloadDevices() {
            if (this.organizationEnable && !this.selectOrganizationAppId) return
            this.showLoading = true
            this.loading = true
            this.devices = []
            let excludeDistrictAppId = undefined
            if (!this.selectDistrictAppId) {
                excludeDistrictAppId = this.districts.map(field => field.appId).filter(name => name !== '')
            }
            let list = []
            let p = 0
            do {
                list = await getPositions(this.selectOrganizationAppId, this.selectDistrictAppId, excludeDistrictAppId?.toString(), p)
                this.devices = [...this.devices, ...list]
                p++
                this.showLoading = false
            } while (list.length !== 0)

            let excludeTags = ["SERVICING.hide"]
            if (excludeDistrictAppId) {
                excludeTags.push(excludeDistrictAppId.map(name => `F:${name}`))
            }
            if (this.vehicleDetectorEnable) {
                list = []
                p = 0
                do {
                    list = (await getDevices({
                        organizationAppId: this.selectOrganizationAppId,
                        districtAppId: this.selectDistrictAppId,
                        excludeTags: excludeTags,
                        page: p
                    })).map(it => {
                        return {...it, deviceId: `${it.id}`, id: null, deviceTags: it.tags, tags: null}
                    })
                    this.devices = [...this.devices, ...list]
                    p++
                    this.showLoading = false
                } while (list.length !== 0)
            }
            if (this.parkingPoleLuciferEnable) {
                list = []
                p = 0
                do {
                    list = (await getParkingPoles({
                        organizationAppId: this.selectOrganizationAppId,
                        districtAppId: this.selectDistrictAppId,
                        excludeTags: excludeTags,
                        page: p
                    })).map(it => {
                        return {
                            ...it,
                            parkingPoleId: `${it.id}`,
                            id: null,
                            deviceTags: it.tags,
                            tags: null,
                            lastPoleStatus: it.lastStatus
                        }
                    })
                    this.devices = [...this.devices, ...list]
                    p++
                    this.showLoading = false
                } while (list.length !== 0)
            }
            this.loading = false
        },

        /**
         * 設備更新組織
         * */
        async onDeviceChangeOrganization(device, organization) {
            this.showLoading = true
            this.loading = true
            let response = {ok: true, message: ''}
            if (Array.isArray(device)) {
                if (organization.id === '') {
                    let list = device.map(d => d.deviceId)
                    response = await deviceUnbindOrganization(list)
                    this.devices = this.devices.filter(item => !list.includes(item.deviceId))
                } else {
                    let list = device.map(d => d.deviceId)
                    response = await deviceBindOrganization(list, organization.appId)
                    this.devices = this.devices.filter(item => !list.includes(item.deviceId))
                }
            } else {
                if (organization.id === '') {
                    response = await deviceUnbindOrganization(device.deviceId)
                    this.devices = this.devices.filter(item => item.deviceId !== device.deviceId)
                } else if (device.organizationAppId !== organization.appId) {
                    response = await deviceBindOrganization(device.deviceId, organization.appId)
                    this.devices = this.devices.filter(item => item.deviceId !== device.deviceId)
                }
            }
            this.loading = false
            this.showLoading = false
            if (response.ok) {
                toast().success('更新成功')
            } else {
                toast().error(response.message)
            }
        },

        /**
         * 設備更新場域
         * */
        async onDeviceChangeField(device, field) {
            this.showLoading = true
            this.loading = true
            let response = {ok: true, message: ''}
            if (Array.isArray(device)) {
                if (field.id === '') {
                    let list = device.map(d => d.deviceId)
                    response = await deviceUnbindField(list)
                    this.devices = this.devices.filter(item => !list.includes(item.deviceId))
                } else {
                    let list = device.map(d => d.deviceId)
                    response = await deviceBindField(list, field.id)
                    this.devices = this.devices.filter(item => !list.includes(item.deviceId))
                }
            } else {
                if (field.id === '') {
                    response = await deviceUnbindField(device.deviceId)
                } else if (device.fieldName !== field.appId) {
                    response = await deviceBindField(device.deviceId, field.id)
                }
                this.devices = this.devices.filter(item => item.deviceId !== device.deviceId)
            }
            this.loading = false
            this.showLoading = false
            if (response.ok) {
                toast().success('更新成功')
            } else {
                toast().error(response.message)
            }
        },

        reloadView() {
            this.devices = [...this.devices]
        },

        async updateDeviceView(oldParkingPosition, newParkingPosition, devices) {
            let temp;
            if (devices) {
                temp = devices
            } else {
                // eslint-disable-next-line no-unused-vars
                temp = this.devices
            }
            const oldIdIndex = temp.findIndex(parkingPosition => !!oldParkingPosition.id && parkingPosition.id === oldParkingPosition.id)
            const oldDeviceIdIndex = temp.findIndex(parkingPosition => !!oldParkingPosition.deviceId && parkingPosition.deviceId === oldParkingPosition.deviceId)
            const newIdIndex = temp.findIndex(parkingPosition => !!newParkingPosition.id && parkingPosition.id === newParkingPosition.id)
            const newDeviceIdIndex = temp.findIndex(parkingPosition => !!newParkingPosition.deviceId && parkingPosition.deviceId === newParkingPosition.deviceId)
            let indexList = []
            if (!indexList.includes(oldIdIndex)) {
                if (oldIdIndex !== -1) {
                    temp[oldIdIndex] = await getPosition(oldParkingPosition.id)
                }
                indexList.push(oldIdIndex)
            }
            if (!indexList.includes(oldDeviceIdIndex)) {
                if (oldDeviceIdIndex !== -1) {
                    if (newParkingPosition.id) {
                        temp[oldDeviceIdIndex] = await getPosition(newParkingPosition.id)
                    } else {
                        temp[oldDeviceIdIndex] = newParkingPosition
                    }
                }
                indexList.push(oldDeviceIdIndex)
            }
            if (!indexList.includes(newIdIndex)) {
                if (newIdIndex !== -1) {
                    temp[newIdIndex] = await getPosition(newParkingPosition.id)
                }
                indexList.push(newIdIndex)
            }
            if (!indexList.includes(newDeviceIdIndex)) {
                if (newDeviceIdIndex !== -1) {
                    if (oldParkingPosition.id !== newParkingPosition.id) {
                        temp[newDeviceIdIndex] = await getPosition(oldParkingPosition.id)
                    } else {
                        temp[newDeviceIdIndex] = {
                            ...oldParkingPosition,
                            id: null,
                            address: null,
                            name: null,
                            lat: null,
                            lng: null,
                            isDelete: null
                        }
                    }
                }
                indexList.push(newDeviceIdIndex)
            }
            if (oldIdIndex === newIdIndex && oldDeviceIdIndex === -1 && newDeviceIdIndex !== -1) {
                temp = [...this.devices.filter((_, i) => i !== newDeviceIdIndex)]
                return temp
            }
            if (oldIdIndex === newIdIndex && oldDeviceIdIndex !== -1 && newDeviceIdIndex === -1) {
                temp.push({
                    ...oldParkingPosition,
                    id: null,
                    address: null,
                    name: null,
                    lat: null,
                    lng: null,
                    isDelete: null
                })
            }
            if (oldIdIndex === -1 && oldIdIndex === oldDeviceIdIndex && oldIdIndex === newIdIndex && oldIdIndex === newDeviceIdIndex) {
                temp.push(newParkingPosition)
            }
            temp = [...temp]
            return temp
        }
    }
})

export const toast = defineStore('toast', {
    state: () => {
        return {
            show: false,
            message: '',
            color: 'success'
        }
    },
    actions: {
        dismiss() {
            this.show = false
        },
        success(message, color) {
            this.message = message
            this.color = color ?? 'success'
            this.show = true
        },
        error(message) {
            this.message = message
            this.color = 'error'
            this.show = true
        }
    }
})

export const useLoadingStore = defineStore('loading', {
    state: () => {
        return {
            count: 0,
            visibility: false
        }
    },
    actions: {
        showLoading() {
            this.count++
            this.visibility = this.count > 0
        },
        dismissLoading() {
            this.count--
            this.visibility = this.count > 0
        }
    }
})

export const userLocation = defineStore('location', {
    state: () => {
        return {
            lat: 25.0475423,
            lng: 121.515211
        }
    },
    actions: {
        init() {
            if ("geolocation" in navigator) {
                let temp = this
                navigator.geolocation.getCurrentPosition(function (position) {
                    temp.lat = position.coords.latitude
                    temp.lng = position.coords.longitude
                })
            }
        },
        setLatLng({lat, lng}) {
            this.lat = lat
            this.lng = lng
        }
    }
})

export const useTagStore = defineStore('tag', {
    state: () => {
        return {tags: []}
    },
    actions: {
        /**
         * 可選參數
         * @param scope 指定標籤作用範圍，未指定為原本的地磁
         */
        async init(scope) {
            if (this.tags.length > 0) return
            let originTags = []
            if (scope) {
                if (scope === 'parking-pole') {
                    (await getParkingPoleAllTags()).forEach(tag => {
                        originTags.push(tag)
                    })
                }
            }
            if (originTags.length === 0) originTags = await getAllTags()
            this.tags = originTags.filter(tag => {
                console.log(tag)
                if (tag.includes('C:')) return false
                if (tag.includes('F:')) return false
                if (tag.includes('.hide')) return false
                return true
            })
            if (!this.tags.includes('廢品.red')) {
                this.tags.push('廢品.red')
            }
            if (!this.tags.includes('維修.warning')) {
                this.tags.push('維修.warning')
            }
        },
        add(tag) {
            if (this.tags.indexOf(tag) === -1) {
                this.tags = [...this.tags, tag]
            }
        }
    }
})