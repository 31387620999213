<template>
  <v-app>
    <v-main>
      <v-row>
        <v-col class="text-center">
          <img :src="logo" width="256" height="256"/>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-col class="text-center">
          <h1>Error {{ errorCode }}</h1>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import logo from '../../public/logo.png'


export default {
  name: "ErrorPage",
  props: {
    errorCode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {logo}
  }
}
</script>

<style scoped>

</style>