<template>
  <v-container fluid>
    <v-row>
      <v-col>
      </v-col>
      <v-col col="12" sm="2">
        <v-btn color="primary" right @click="createUser=true">新增</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="users" :options="options">
      <template slot="role" slot-scope="{row}">
        <span v-show="row.role==='ROOT'" class="red--text">管理員</span>
        <span v-show="row.role==='MANAGER'" class="primary--text">經理</span>
        <span v-show="row.role==='STAFF'" class="primary--text">員工</span>
        <span v-show="row.role==='LEADER'">組長</span>
        <span v-show="row.role==='MEMBER'" class="amber--text">合作夥伴</span>
        <span v-show="row.role==='USER'">使用者</span>
      </template>
      <template slot="action" slot-scope="{row}">
        <v-btn-toggle>
          <v-btn color="primary" small outlined @click="$router.push(
              {path: `/user/${row.username}`,query: {...$route.query}}
              )">編輯
          </v-btn>
          <v-btn color="primary" small outlined @click="updatePasswordUser=row">重設密碼</v-btn>
          <v-btn color="error" small outlined @click="disableUser=row">停用</v-btn>
        </v-btn-toggle>
      </template>
    </v-client-table>
    <v-dialog
        content-class="w-50"
        :value="createUser"
        @click:outside="createUser=undefined">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-btn icon
                 @click="createUser=undefined;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <CreateUserFrom @close="createUser=undefined;loadUsers()"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        content-class="w-50"
        :value="disableUser"
        @click:outside="disableUser=undefined">
      <v-card v-if="!!disableUser">
        <v-card-title>
          <v-spacer/>
          <v-btn icon
                 @click="disableUser=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center text-lg-h6">是否要停用 {{ disableUser.username }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 @click="updateUser({userId:disableUser.id,enable:false});disableUser=undefined">儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        content-class="w-50"
        :value="updatePasswordUser"
        @click:outside="updatePasswordUser=undefined">
      <v-card v-if="!!updatePasswordUser">
        <v-card-title>
          <v-spacer/>
          <v-btn icon
                 @click="updatePasswordUser=undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UpdatePasswordFrom :user-id="updatePasswordUser.id"
                              @close="updatePasswordUser=undefined"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {getUsers, adminPatchUser} from "@/Api";
import {mapActions} from "pinia";
import {useLoadingStore} from "@/Store";
import UpdatePasswordFrom from "@/components/UpdatePasswordFrom";
import CreateUserFrom from "@/components/CreateUserFrom";

export default {
  name: "UserFrom",
  components: {CreateUserFrom, UpdatePasswordFrom},
  data() {
    return {
      columns: ['username', 'role', 'action'],
      users: [],
      options: {
        headings: {
          username: '使用者名稱',
          role: '角色',
          action: '操作'
        },
        orderBy: {ascending: true, column: 'username'},
        sortable: ['username'],
        filterable: false,
      },
      createUser: undefined,
      disableUser: undefined,
      updatePasswordUser: undefined
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async loadUsers() {
      this.showLoading()
      this.users = await getUsers()
      this.dismissLoading()
    },
    async updateUser({userId, password, role, enable}) {
      this.showLoading()
      await adminPatchUser({userId, password, role, enable})
      this.users = await getUsers()
      this.dismissLoading()
    }
  },
  async mounted() {
    await this.loadUsers()
  }
}
</script>

<style scoped>

</style>