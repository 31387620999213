<template>
  <div>
    <v-row>
      <v-col col="12" sm="9">
        <div class="input-group">
          <date-picker
              v-model="dateRange[0]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
          <span class="input-group-text bg-transparent border-0">~</span>
          <date-picker
              v-model="dateRange[1]"
              :editable="false"
              range-separator="~"
              value-type="date"
              format="YYYY-MM-DD"/>
        </div>
      </v-col>
      <v-col class="text-end" col="12" sm="1">
        <v-btn color="primary" @click="send" :disabled="loading">搜尋</v-btn>
      </v-col>
      <v-col class="text-start" col="12" sm="2">
        <v-btn color="primary" @click="exportData" :disabled="loading" v-show="data.length > 0">匯出xlsx</v-btn>
      </v-col>
    </v-row>
    <hr>
    <v-client-table ref="table"
                    :columns="columns" :data="data" :options="options"
                    @pagination="onLoaded($event)">
      <span slot="time" slot-scope="{row}">
        {{ $moment(row.time).format('YYYY-MM-DD HH:mm:ss') }}
      </span>
      <span slot="isOccupy" slot-scope="{row}">
          {{ row.isOccupy ? '是' : '否' }}
      </span>
    </v-client-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {mainStore, useLoadingStore} from "@/Store";
import {utils, writeFile} from "xlsx";
import {getParkingPoleChangeLogs} from "@/Api-parking-pole";

export default {
  name: "ParkingPoleChangeLogFrom",
  props: {
    deviceId: String
  },
  computed: {
    ...mapState(useLoadingStore, {loading: 'visibility'}),
    ...mapState(mainStore, ['userRole']),

  },
  data() {
    return {
      dateRange: [this.$moment().subtract(7, 'days').toDate(), this.$moment().toDate()],
      startTime: null,
      endTime: null,
      columns: ['m', 'd', 'probability', 'isOccupy', 'time'],
      data: [],
      options: {
        headings: {
          m: '地磁繞擾動',
          d: '紅外值',
          probability: '有車概率(%)',
          isOccupy: '是否有車',
          time: '發生時間'
        },
        orderBy: {ascending: false, column: 'time'},
        sortable: ['m', 'd', 'probability', 'isOccupy', 'time'],
        filterable: false
      }
    }
  },
  mounted() {
    console.log(this.userRole)
    if (this.userRole === 'USER') {
      this.columns =  ['isOccupy', 'time']
    }else {
      this.columns = ['m', 'd', 'probability', 'isOccupy', 'time']
    }
    console.log(this.columns)
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async send() {
      this.startTime = this.$moment(this.dateRange[0]).set("hour", 0).set("minute", 0).set('second', 0).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.endTime = this.$moment(this.dateRange[1]).set("hour", 23).set("minute", 59).set('second', 59).utc().format("YYYY-MM-DDTHH:mm:ss")
      this.showLoading()
      this.data = await getParkingPoleChangeLogs(this.deviceId, this.startTime, this.endTime, 0)
      this.dismissLoading()
    },
    async onLoaded(event) {
      if (event === parseInt(this.data.length / 10)) {
        let page = parseInt(this.data.length / 100) + (this.data.length % 100 > 0 ? 1 : 0)
        this.showLoading()
        let list = await getParkingPoleChangeLogs(this.deviceId, this.startTime, this.endTime, page)
        if (list.length > 0) {
          this.data = [...this.data, ...list]
        }
        this.dismissLoading()
      }
    },
    exportData() {
      const row = []
      if(['USER'].includes(this.userRole)){
        let isOccupy = undefined
        this.data.reverse().forEach(item => {
          if (isOccupy === item.isOccupy) return
          row.push({
            '是否有車': item.isOccupy ? '是' : '否',
            '發生時間': this.$moment(item.time).format('YYYY-MM-DD HH:mm:ss')
          })
          isOccupy = item.isOccupy
        })
      }else {
        this.data.reverse().forEach(item => {
          row.push({
            '地磁繞擾動': item.m,
            '紅外值': item.d,
            '有車概率(%)': item.probability,
            '是否有車': item.isOccupy ? '是' : '否',
            '發生時間': this.$moment(item.time).format('YYYY-MM-DD HH:mm:ss')
          })
        })
      }
      const ws = utils.json_to_sheet(row.reverse());
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Sheet1");
      writeFile(wb, `設備${this.deviceId}車位變換紀錄.xlsx`, {bookType: "xlsx"});
    }
  }
}
</script>

<style scoped>

</style>