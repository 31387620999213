<template>
  <div class="w-100 h-100" ref="container" v-resize="onResize">
    <GmapMap class="w-100 h-100"
             :center="{lat: userLat, lng: userLng}"
             :zoom="16"
             :position="google"
             :options="{
               fullscreenControl:false,
               streetViewControl: false
             }"
             ref="map">
      <GmapCluster :minimumClusterSize="11">
        <GmapMarker
            v-for="(device,i) in devices.filter(item=>(!!item.lat && !!item.lng)).filter(filterMethod)"
            :key="i"
            :position="{lat:device.lat,lng:device.lng}"
            :clickable="false"
            :draggable="true"
            :icon="{
                url: getIcon(device),
                scaledSize :{width:36, height:36},
              }"
            @mouseover="mouseEnterMarker(device)"
            @dragend="onMarkerDrag($event,device)"
        />
      </GmapCluster>
    </GmapMap>
    <div class="position-absolute row" :style="{top:cardTop,left:cardLeft,width:cardWidth, minWidth: `320px`}">
      <v-col>
        <v-card>
          <v-container fluid>
            <v-form @submit.prevent="search">
              <v-expansion-panels accordion flat readonly v-model="expansion" multiple>
                <v-expansion-panel key="0">
                  <v-expansion-panel-header class="p-0 align-items-baseline">
                    <v-text-field v-model="keyword"
                                  append-outer-icon="send"
                                  append-icon="arrow_drop_down"
                                  placeholder="車格編號、地址、地磁ID"
                                  dense
                                  flat
                                  hide-details
                                  @click:append="tagging"
                                  @click:append-outer="search"/>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field v-model="excludeKeyword"
                                  placeholder="車格編號、地址、地磁ID"
                                  label="排除關鍵字"
                                  dense/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col class="text-end" cols="3">
        <v-btn color="primary" fab @click="reloadDevices">
          <v-icon>autorenew</v-icon>
        </v-btn>
      </v-col>
    </div>
  </div>
</template>

<script>
import {patchPosition} from "@/Api";
import {gmapApi} from "vue2-google-maps";
import {mapActions, mapState} from "pinia";
import {mainStore, toast, useLoadingStore, userLocation} from "@/Store";
// eslint-disable-next-line no-unused-vars
import {filterDeviceWithKeywordAndExcludeKeyword, getIcon} from "@/plugins/methods";
import {ref} from "vue";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "EditPositionLatlngMapFrom",
  components: {
    GmapCluster
  },
  computed: {
    google: gmapApi,
    ...mapState(mainStore, ['devices', 'userRole','reloadDevices', 'updateDeviceView']),
    ...mapState(userLocation, {
      userLat: 'lat',
      userLng: 'lng'
    }),
  },
  setup() {
    return {
      map: ref('map')
    }
  },
  data() {
    return {
      mapHeight: 600,
      cardWidth: '0px',
      cardTop: '20px',
      cardLeft: '0px',
      keyword: '',
      excludeKeyword: '',
      expansion: [],
      newLatLng: {},
      moveEnable: true,
    }
  },
  watch: {
    devices: {
      handler: function (value) {
        let devices = value.filter(item => (!!item.lat && !!item.lng))
        if (devices[0] && this.moveEnable) {
          this.$refs.map?.panTo({lat: devices[0].lat, lng: devices[0].lng})
        }
        this.moveEnable = false
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    getIcon(device) {
      return getIcon(device, this.userRole)
    },
    onResize() {
      let cardWidth = window.innerWidth * 0.60
      if (cardWidth < 320) {
        cardWidth = 320
      }
      this.cardWidth = `${cardWidth}px`
      let left = (window.innerWidth * 0.5 - cardWidth * 0.5)
      this.cardLeft = `${left}px`
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.cardTop = '20px'
      } else {
        this.cardTop = '72px'
      }
    },
    filterMethod(device) {
      return filterDeviceWithKeywordAndExcludeKeyword(device, this.keyword, this.excludeKeyword, this.userRole)
    },
    tagging() {
      if (this.expansion.length === 0) {
        this.expansion = [0]
      } else {
        this.expansion = []
      }
    },
    async search() {
      let device = this.devices.filter(this.filterMethod)[0]
      if (device) {
        this.$refs.map?.panTo({lat: device.lat, lng: device.lng})
      }
    },
    async onMarkerDrag(event, device) {
      await patchPosition(device.id, {lat: event.latLng.lat(), lng: event.latLng.lng()})
      await this.updateDeviceView(device, device)
    },
    mouseEnterMarker(device) {
      this.success(`${device.address}${device.name}${device.deviceId ? `(${device.deviceId})` : ''}`, 'white')
    }
  }
}
</script>

<style scoped>

</style>