<template>
  <v-container fluid>
    <v-row>
      <v-col/>
      <v-col col="12" sm="2">
        <v-btn color="primary" @click="showCreateOrganizationModal = true" right>新增</v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-client-table :columns="columns" :data="devices" :options="options">
      <template slot="action" slot-scope="{row}">
        <v-btn-toggle>
          <v-btn color="primary" small outlined @click="toEditUser(row)">編輯使用者</v-btn>
          <v-btn color="orange" small outlined @click="toEditField(row)">編輯場域</v-btn>
          <v-btn color="error" small outlined
                 @click="selectDeleteOrganization=row;showDeleteOrganizationModal=true">刪除
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-client-table>
    <v-dialog scrollable
              v-model="showCreateOrganizationModal"
              @click:outside="showCreateOrganizationModal=false">
      <v-card>
        <v-card-title>
          <label>新增組織</label>
          <v-spacer/>
          <v-btn icon @click="showCreateOrganizationModal=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field label="名稱" v-model="newName" :rules="nameRules" :counter="255"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="AppId" v-model="newAppId" :rules="appIdRules" :counter="255"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 right
                 @click="sendCreate">儲存
          </v-btn>
          <v-btn color="error"
                 right
                 @click="showCreateOrganizationModal=false">取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable
              v-model="showDeleteOrganizationModal"
              @click:outside="showDeleteOrganizationModal = false">
      <v-card>
        <v-card-title>
          <label>刪除組織</label>
          <v-spacer/>
          <v-btn icon @click="showDeleteOrganizationModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center fs-4">
          確認刪除 {{ selectDeleteOrganization?.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 right
                 @click="sendDelete">確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="createSuccess" color="success">新增成功</v-snackbar>
    <v-snackbar v-model="createFailure" color="error">新增失敗</v-snackbar>
    <v-snackbar v-model="deleteSuccess" color="success">刪除成功</v-snackbar>
    <v-snackbar v-model="deleteFailure" color="error">刪除失敗</v-snackbar>
  </v-container>
</template>

<script>
import {createOrganization, deleteOrganization, getAllOrganizations} from "@/Api";
import {mapActions, mapState} from "pinia";
import {useLoadingStore} from "@/Store";

export default {
  name: "OrganizationFrom",
  computed: {
    ...mapState(useLoadingStore, ['visibility'])
  },
  data() {
    return {
      columns: ['name', 'appId', 'action'],
      devices: [],
      options: {
        headings: {
          name: '名稱',
          appId: 'ID',
          action: '操作'
        },
        orderBy: {ascending: true, column: 'id'},
        sortable: ['name', 'appId'],
        filterable: false,
      },
      showCreateOrganizationModal: false,
      newName: '',
      newAppId: '',
      nameRules: [
        v => !!v || '缺少名稱',
        v => (v && v.length <= 255) || '名稱過長',
      ],
      appIdRules: [
        v => !!v || '缺少AppId',
        v => (v && v.length <= 255) || 'AppId過長',
      ],
      showDeleteOrganizationModal: false,
      selectDeleteOrganization: undefined,
      createSuccess: false,
      createFailure: false,
      deleteSuccess: false,
      deleteFailure: false,
    }
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    async loadOrganizations() {
      this.showLoading()
      this.devices = await getAllOrganizations()
      this.dismissLoading()
    },
    async sendCreate() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.showLoading()
      const success = await createOrganization({name: this.newName, appId: this.newAppId})
      if (success) {
        this.createSuccess = true
      } else {
        this.createFailure = true
      }
      this.$refs.form.reset()
      this.showCreateOrganizationModal = false
      await this.loadOrganizations()
      this.dismissLoading()
    },
    toEditField(organization) {
      this.$router.push({
        path: `/organization/field/${organization.appId}`,
        query: {...this.$route.query}
      })
    },
    toEditUser(organization) {
      this.$router.push({
        path: `/organization/user/${organization.id}`,
        query: {...this.$route.query}
      })
    },
    async sendDelete() {
      this.showLoading()
      const id = this.selectDeleteOrganization?.id
      if (id) {
        const success = await deleteOrganization(id)
        if (success) {
          this.deleteSuccess = true
        } else {
          this.deleteFailure = true
        }
      }
      this.selectDeleteOrganization = undefined
      this.showDeleteOrganizationModal = false
      await this.loadOrganizations()
      this.dismissLoading()
    }
  },
  mounted() {
    this.loadOrganizations().then()
  }
}
</script>

<style scoped>

</style>