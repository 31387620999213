<template>
  <div class="w-100 h-100" ref="container" v-resize="onResize">
    <GmapMap class="w-100 h-100"
             :center="{lat: userLat, lng: userLng}"
             :zoom="16"
             :position="google"
             :options="{
               fullscreenControl:false,
               streetViewControl: false
             }"
             ref="map">
      <GmapCluster :minimumClusterSize="11">
        <GmapMarker
            v-for="(device,i) in devices.filter(item=>(!!item.lat && !!item.lng)).filter(filterMethod)"
            :key="i"
            :position="{lat:device.lat,lng:device.lng}"
            :clickable="true"
            :draggable="false"
            :icon="{
                url: getIcon(device),
                scaledSize :{width:36, height:36},
              }"
            @click="toggleInfoWindow(device,i)"
            @mouseover="mouseEnterMarker(device)"
        />
      </GmapCluster>
    </GmapMap>
    <div class="position-absolute row" :style="{top:cardTop,left:cardLeft,width:cardWidth, minWidth: `320px`}">
      <v-col>
        <v-card>
          <v-container fluid>
            <v-form @submit.prevent="search">
              <v-expansion-panels accordion flat readonly v-model="expansion" multiple>
                <v-expansion-panel key="0">
                  <v-expansion-panel-header class="p-0 align-items-baseline">
                    <v-text-field v-model="keyword"
                                  append-outer-icon="send"
                                  append-icon="arrow_drop_down"
                                  placeholder="車格編號、地址、地磁ID"
                                  dense
                                  flat
                                  hide-details
                                  @click:append="tagging"
                                  @click:append-outer="search"/>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field v-model="excludeKeyword"
                                  placeholder="車格編號、地址、地磁ID"
                                  label="排除關鍵字"
                                  dense/>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col class="text-end" cols="3">
        <v-btn color="primary" fab @click="reloadDevices">
          <v-icon>autorenew</v-icon>
        </v-btn>
      </v-col>
    </div>
    <v-dialog
        content-class="h-100"
        scrollable
        :value="selectDevice"
        @click:outside="selectDevice = undefined">
      <v-card class="h-100" v-if="!!selectDevice">
        <v-card-title>
          {{ selectDevice.name }}
          <v-spacer/>
          <v-btn icon
                 @click="selectDevice = undefined">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" v-if="['ROOT','MANAGER'].includes(userRole)">
            <v-tab key="資本資料">資本資料</v-tab>
            <v-tab key="封包紀錄">封包紀錄</v-tab>
            <v-tab key="狀態紀錄">狀態紀錄</v-tab>
            <v-tab key="異常紀錄">異常紀錄</v-tab>
            <v-tab key="設定">設定</v-tab>
            <v-tab key="韌體">韌體</v-tab>
          </v-tabs>
          <v-tabs v-model="tab" v-if="['MEMBER'].includes(userRole)">
            <v-tab key="資本資料">資本資料</v-tab>
            <v-tab key="封包紀錄">封包紀錄</v-tab>
            <v-tab key="狀態紀錄">狀態紀錄</v-tab>
            <v-tab key="異常紀錄">異常紀錄</v-tab>
          </v-tabs>
          <v-tabs v-model="tab" v-else-if="['USER'].includes(userRole)">
            <v-tab key="資本資料">資本資料</v-tab>
            <v-tab key="狀態紀錄">狀態紀錄</v-tab>
          </v-tabs>
        </v-card-text>
        <v-card-text class="h-100">
          <v-tabs-items v-model="tab">
            <v-tab-item key="資本資料">
              <v-container fluid>
                <v-simple-table>
                  <tbody>
                  <tr>
                    <td class="col-sm-2">車格編號</td>
                    <td class="col-sm-10">{{ selectDevice.name }}</td>
                  </tr>
                  <tr>
                    <td class="col-sm-2">地址</td>
                    <td class="col-sm-10">{{ selectDevice.address }}</td>
                  </tr>
                  <tr>
                    <td class="col-sm-2">地磁</td>
                    <td class="col-sm-10">{{ selectDevice.deviceId }}</td>
                  </tr>
                  <tr>
                    <td class="col-sm-2">是否有車</td>
                    <td class="col-sm-10">
                      <span v-show="selectDevice.lastStatus"
                            :class="(selectDevice.lastStatus?.cp ?? 0) >= 50 ? 'red--text':'green--text'">
                        <apan v-if="(selectDevice.lastStatus?.cp ?? 0) >= 50">有車</apan>
                        <apan v-else>無車</apan>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-sm-2">電池電壓</td>
                    <td class="col-sm-10">
                      <span v-show="selectDevice.lastStatus"
                            :class="(selectDevice.lastStatus?.batteryVoltage ?? 0) < 3300 ? 'red--text':'green--text'">
                        {{ (selectDevice.lastStatus?.batteryVoltage ?? 0) / 1000 }} V
                      </span>
                    </td>
                  </tr>
                  <tr v-show="['ROOT', 'MANAGER'].includes(userRole)">
                    <td class="col-sm-2">異常</td>
                    <td class="col-sm-10">
                      <v-chip-group>
                        <v-chip v-for="tag in (selectDevice.deviceTags??[]).filter(tag=>tag.includes('E:'))"
                                :color="getChipColor(tag)"
                                v-bind:key="tag">
                          <div v-if="tag.includes('E:DISCONNECTED')">斷線異常</div>
                          <div v-else-if="tag.includes('E:LOW_POWER')">低電量異常</div>
                          <div v-else-if="tag.includes('E:MAGNETIC')">磁場異常</div>
                          <div v-else-if="tag.includes('E:INFRARED')">紅外異常</div>
                          <div v-else-if="tag.includes('E:BOR')">復位異常</div>
                          <div v-else-if="tag.includes('E:COVER')">遮蔽異常</div>
                          <div v-else-if="tag.includes('E:INIT')">初始化異常</div>
                          <div v-else-if="tag.includes('E:RSRP')">訊號異常</div>
                          <div v-else-if="tag.includes('E:SINR')">信噪比異常</div>
                          <div v-else-if="tag.includes('E:SIM')">SIM卡異常</div>
                          <div v-else-if="tag.includes('E:LONG_PARKING')">疑似長時間停車</div>
                          <div v-else-if="tag.includes('E:NETWORK')">網路異常</div>
                          <div v-else>{{ tag.replace('E:', '').replace('.hide', '') }}</div>
                        </v-chip>
                      </v-chip-group>
                    </td>
                  </tr>
                  <tr>
                    <td class="col text-end" colSpan="2">
                      <v-btn color="primary" @click="openGoogleNavigation(selectDevice)">開啟Google導航</v-btn>
                    </td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </v-container>
            </v-tab-item>
            <v-tab-item key="封包紀錄" v-if="['ROOT','MANAGER','MEMBER'].includes(userRole)">
              <DeviceStatusFrom :device-id="selectDevice.deviceId"/>
            </v-tab-item>
            <v-tab-item key="狀態紀錄">
              <ChangeLogFrom :device-id="selectDevice.deviceId"/>
            </v-tab-item>
            <v-tab-item key="異常紀錄" v-if="['ROOT','MANAGER','MEMBER'].includes(userRole)">
              <UnmoralLogFrom :device-id="selectDevice.deviceId"/>
            </v-tab-item>
            <v-tab-item key="設定" v-if="['ROOT','MANAGER'].includes(userRole)">
              <SettingFrom :device-id="[selectDevice.deviceId]"/>
            </v-tab-item>
            <v-tab-item key="韌體" v-if="['ROOT','MANAGER'].includes(userRole)">
              <DeviceUpgradeFrom :device-id="selectDevice.deviceId"
                                 :firmware-version="selectDevice.firmwareVersion"
                                 :bid="selectDevice.bid"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref} from 'vue'
import {gmapApi} from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import DeviceStatusFrom from "@/components/DeviceStatusFrom";
import ChangeLogFrom from "@/components/ChangeLogFrom";
import UnmoralLogFrom from "@/components/UnmoralLogFrom";
import SettingFrom from "@/components/SettingFrom";
import DeviceUpgradeFrom from "@/components/DeviceUpgradeFrom";
import {useLoadingStore, mainStore, userLocation, toast} from "@/Store";
import {mapState, mapActions} from 'pinia';
import redMark from '@/assets/map-mark-red.svg'
import greyMark from '@/assets/map-mark-grey.svg'
import greenMark from '@/assets/map-mark-green.svg'
//import pinkMark from '@/assets/map-mark-pink.svg'
//import mintMark from '@/assets/map-mark-mint.svg'
import redPurpleMark from '@/assets/map-mark-red-purple.svg'
import greenPurpleMark from '@/assets/map-mark-green-purple.svg'
//import pinkPurpleMark from '@/assets/map-mark-pink-purple.svg'
//import mintPurpleMark from '@/assets/map-mark-mint-purple.svg'
import userRedMark from '@/assets/parking-mark-red.svg'
import userGreyMark from '@/assets/parking-mark-grey.svg'
import userGreenMark from '@/assets/parking-mark-green.svg'
//import userPinkMark from '@/assets/parking-mark-pink.svg'
//import userMintMark from '@/assets/parking-mark-mint.svg'
import userRedPurpleMark from '@/assets/parking-mark-red-purple.svg'
import userGreenPurpleMark from '@/assets/parking-mark-green-purple.svg'
//import userPinkPurpleMark from '@/assets/parking-mark-pink-purple.svg'
//import userMintPurpleMark from '@/assets/parking-mark-mint-purple.svg'
import {filterDeviceWithKeywordAndExcludeKeyword} from "@/plugins/methods";

export default {
  name: 'App',
  components: {
    GmapCluster,
    DeviceStatusFrom,
    ChangeLogFrom,
    UnmoralLogFrom,
    SettingFrom,
    DeviceUpgradeFrom
  },
  computed: {
    google: gmapApi,
    ...mapState(mainStore, ['selectOrganizationAppId', 'selectFieldName', 'devices', 'reloadDevices', 'userRole', 'updateDeviceView']),
    ...mapState(userLocation, {
      userLat: 'lat',
      userLng: 'lng'
    }),
  },
  setup() {
    return {
      map: ref('map')
    }
  },
  data() {
    return {
      mapHeight: 600,
      cardWidth: '0px',
      cardTop: '20px',
      cardLeft: '0px',
      selectDevice: undefined,
      keyword: '',
      excludeKeyword: '',
      expansion: [],
      tab: null,
      updateLatLngDevice: undefined,
      newLatLng: {},
      moveEnable: true,
    }
  },
  watch: {
    devices: {
      handler: function (value) {
        let devices = value.filter(item => (!!item.lat && !!item.lng))
        if (devices[0] && this.moveEnable) {
          this.$refs.map?.panTo({lat: devices[0].lat, lng: devices[0].lng})
        }
        this.moveEnable = false
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(toast, ['success', 'error']),
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
    getIcon(device) {
      if (!device.lastStatus) return this.userRole === 'USER' ? userGreyMark : greyMark
      let tag = (device.deviceTags ?? []).join(',')
      let haveError = tag.includes('E:DISCONNECTED')
          || tag.includes('E:LOW_POWER')
          || tag.includes('E:MAGNETIC')
          || tag.includes('E:INFRARED')
          || tag.includes('E:BOR')
          || tag.includes('E:COVER')
      if (this.userRole === 'USER') {
        if (haveError) {
          if ((device.lastStatus?.cp ?? 0) < 40) {
            return userGreenPurpleMark
          } else if (40 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 50) {
            return userGreenPurpleMark
          } else if (50 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 60) {
            return userRedPurpleMark
          } else {
            return userRedPurpleMark
          }
        } else {
          if ((device.lastStatus?.cp ?? 0) < 40) {
            return userGreenMark
          } else if (40 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 50) {
            return userGreenMark
          } else if (50 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 60) {
            return userRedMark
          } else {
            return userRedMark
          }
        }
      } else {
        if (haveError) {
          if ((device.lastStatus?.cp ?? 0) < 40) {
            return greenPurpleMark
          } else if (40 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 50) {
            return greenPurpleMark
          } else if (50 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 60) {
            return redPurpleMark
          } else {
            return redPurpleMark
          }
        } else {
          if ((device.lastStatus?.cp ?? 0) < 40) {
            return greenMark
          } else if (40 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 50) {
            return greenMark
          } else if (50 <= (device.lastStatus?.cp ?? 0) && (device.lastStatus?.cp ?? 0) < 60) {
            return redMark
          } else {
            return redMark
          }
        }
      }
    },
    getChipColor(tag) {
      if (tag.includes('E:LOW_POWER')) return 'red'
      if (tag.includes('E:INFRARED')) return 'red'
      if (tag.includes('E:MAGNETIC')) return 'red'
      if (tag.includes('E:INFRARED')) return 'red'
      if (tag.includes('E:BOR')) return 'red'
      if (tag.includes('E:COVER')) return 'red'
      if (tag.includes('E:INIT')) return 'amber'
      if (tag.includes('E:RSRP')) return 'amber'
      if (tag.includes('E:SINR')) return 'amber'
      if (tag.includes('E:SIM')) return 'amber'
      return 'green'
    },
    onResize() {
      let cardWidth = window.innerWidth * 0.60
      if (cardWidth < 320) {
        cardWidth = 320
      }
      this.cardWidth = `${cardWidth}px`
      let left = (window.innerWidth * 0.5 - cardWidth * 0.5)
      this.cardLeft = `${left}px`
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.cardTop = '20px'
      } else {
        this.cardTop = '72px'
      }
    },
    filterMethod(device) {
      return filterDeviceWithKeywordAndExcludeKeyword(device, this.keyword, this.excludeKeyword, this.userRole)
    },
    toggleInfoWindow(device) {
      this.selectDevice = device
    },
    openGoogleNavigation(device) {
      window.open(` https://www.google.com/maps/dir/?api=1&origin=我的位置&destination=${device.lat},${device.lng}`, '_blank');
    },
    async search() {
      let device = this.devices.filter(this.filterMethod)[0]
      if (device) {
        this.$refs.map?.panTo({lat: device.lat, lng: device.lng})
      }
    },
    tagging() {
      if (this.expansion.length === 0) {
        this.expansion = [0]
      } else {
        this.expansion = []
      }
    },
    mouseEnterMarker(device) {
      this.success(`${device.address}${device.name}${device.deviceId ? `(${device.deviceId})` : ''}`, 'white')
    }
  }
}
</script>

<style>
</style>