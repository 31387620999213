<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">設備ID</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ deviceId }}</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">電信商</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5" v-if="(/^(46601|46603|46688)/.test(net?.imsi ))">遠傳電信
      </v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5" v-else-if="(/^(46697|46699|46693)/.test(net?.imsi))">
        台灣大哥大
      </v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5" v-else-if="(/^(46611|46692)/.test(net?.imsi))">中華電信
      </v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5" v-else>UNKNOWN</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">IMSI</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ net?.imsi }}</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">IMEI</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ net?.imei }}</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">ICCID</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ net?.iccid }}</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">BAND</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ net?.band }}</v-col>
    </v-row>
    <v-row>
      <v-col class="col-sm-6 col-lg-2 text-end text-lg-h5">LAC</v-col>
      <v-col class="col-sm-6 col-lg-10 text-start text-lg-h5"> {{ net?.lac }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getDeviceNet} from "@/Api";
import {mapActions} from "pinia";
import {useLoadingStore} from "@/Store";

export default {
  name: "DeviceNetFrom",
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor
    deviceId: String | Number
  },
  data() {
    return {net: undefined}
  },
  methods: {
    ...mapActions(useLoadingStore, ['showLoading', 'dismissLoading']),
  },
  async mounted() {
    this.showLoading()
    this.net = await getDeviceNet(this.deviceId)
    this.dismissLoading()
  }
}
</script>

<style scoped>

</style>