<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-select
              v-model="parameters.day"
              :items="[{text:'30 天',value:30},
              {text:'45 天',value:45},
              {text:'60 天',value:60},
              {text:'90 天',value:90},
              {text:'180 天',value:180}]"
              outlined
              dense/>
        </v-row>
        <v-row>
          <LineChartGenerator
              :data="chartData"
              :options="chartOptions"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from "pinia";
import {mainStore} from "@/Store";
import {getPositionCount} from "@/Api";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
} from 'chart.js'
import {Line as LineChartGenerator} from 'vue-chartjs'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
)

export default {
  name: "DashboardPage",
  computed: {
    ...mapState(mainStore, ['selectOrganizationAppId', 'selectDistrictAppId']),
  },
  components: {LineChartGenerator},
  watch: {
    selectOrganizationAppId: {
      handler: async function (value) {
        this.parameters = {...this.parameters, selectOrganizationAppId:value}
      },
      immediate: true
    },
    selectDistrictAppId: {
      handler: async function (value) {
        this.parameters = {...this.parameters, selectDistrictAppId:value}
      },
      immediate: true
    },
    parameters: {
      handler: async function (value) {
        console.log(value)
        await this.loadData(value.selectOrganizationAppId, value.selectDistrictAppId, value.day)
      },
      immediate: true
    },
  },
  data() {
    return {
      parameters: {
        selectOrganizationAppId: '',
        selectDistrictAppId: '',
        day: 30,
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: '有綁定車格',
            backgroundColor: '#F57C00',
            fill: true,
            data: []
          },
          {
            label: '全部車格',
            backgroundColor: '#F8BBD0',
            fill: true,
            data: []
          }]
      },
      chartOptions: {}
    }
  },
  methods: {
    async loadData(selectOrganizationAppId, selectDistrictAppId, day) {
      if (!selectOrganizationAppId) return
      let list = []
      if (selectDistrictAppId) {
        list = await getPositionCount(selectOrganizationAppId, selectDistrictAppId, day)
      } else {
        list = await getPositionCount(selectOrganizationAppId, null, day)
      }
      let totalMap = {}
      let bingDeviceMap = {}
      list.forEach(item => {
        let label = this.$moment(item.createTime).format('YY/MM/DD')
        totalMap[label] = (totalMap[label] ?? 0) + item.totalCount
        bingDeviceMap[label] = (bingDeviceMap[label] ?? 0) + item.bingDeviceCount
      })
      let now = this.$moment().add(-day, 'day')
      let labels = []
      let totalData = []
      let bingDeviceData = []
      for (let i = 0; i < day; i++) {
        let date = now.add(1, 'day').format('YY/MM/DD')
        labels.push(date)
        totalData.push(totalMap[date])
        bingDeviceData.push(bingDeviceMap[date])
      }
      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: '有綁定車格',
            backgroundColor: '#F57C00',
            fill: true,
            data: bingDeviceData
          },
          {
            label: '全部車格',
            backgroundColor: '#F8BBD0',
            fill: true,
            data: totalData
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>